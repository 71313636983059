import React from "react";
import {
	FormControl,
	Box,
	Checkbox,
	FormControlLabel,
	Grid,
	TextField,
	Typography,
	Button,
	Popover,
	IconButton,
} from "@mui/material";
import Page from "../../Components/Page";
import PageHeader from "../../Components/PageHeader";
import Error from "../../Components/Error";
import NoData from "../../Components/NoData";
import CircularProgressComp from "../../Components/CircularProgress";
import TitledTextField from "./Components/TitledTextField";
import {
	getComparisonDataApi,
	updateComparisonDataApi,
} from "../../Services/Plans";
import {Add, Delete} from "@mui/icons-material";
import LoadModal from "../../Components/LoadModal";
import SnackbarComp from "../../Components/Snackbar";

const newSectionObject = {
	id: Math.random(),
	sequence: 1,
	name: "new_section",
	title: "New Section",
	header: true,
	features: [],
};

function PlanComparison(props) {
	const [loadPage, setLoadPage] = React.useState("load");
	const [loadModal, setLoadModal] = React.useState(false);

	const [anchorEl, setAnchorEl] = React.useState(null);
	const [state, setState] = React.useState({
		sectionId: "",
		sectionIndex: "",
		action: "section",
	});

	const [snackbarState, setSnackbarState] = React.useState({
		open: false,
		severity: "info",
		message: "section",
	});

	const open = Boolean(anchorEl);
	const id = open ? "AddFeaturePopover" : undefined;

	const [data, setData] = React.useState([]);
	const [plans, setPlans] = React.useState([]);

	const getComparisonDataApiCall = React.useCallback(() => {
		setLoadPage("load");
		getComparisonDataApi()
			.then((res) => {
				setData(res.Item.features);
				setPlans(res.Item.plans);
				setLoadPage("found");
			})
			.catch((err) => {
				console.error(err);
				setLoadPage("error");
			});
	}, []);

	React.useEffect(() => {
		getComparisonDataApiCall();
	}, [getComparisonDataApiCall]);

	const debounce = (cb, delay = 2000) => {
		let timeout;
		return (...args) => {
			clearTimeout(timeout);
			timeout = setTimeout(() => {
				cb(...args);
			}, delay);
		};
	};

	const handleChangeFeature = debounce((data, eleId, itemId) => {
		const {name, value} = data;
		setData((prev) =>
			prev.map((fEle) => {
				if (fEle.id === eleId) {
					return {
						...fEle,
						features: fEle.features.map((fItem) => {
							if (fItem.id === itemId) {
								return {
									...fItem,
									[name]: value,
								};
							} else return fItem;
						}),
					};
				} else return fEle;
			})
		);
	});

	const handleChangeFeatureCheck = (data, eleId, itemId) => {
		const {name, value} = data;
		setData((prev) =>
			prev.map((fEle) => {
				if (fEle.id === eleId) {
					return {
						...fEle,
						features: fEle.features.map((fItem) => {
							if (fItem.id === itemId) {
								return {
									...fItem,
									[name]: value,
								};
							} else return fItem;
						}),
					};
				} else return fEle;
			})
		);
	};

	const handleChangeMainTitle = debounce((e, eleId) => {
		const {value} = e.target;
		setData((prev) =>
			prev.map((fEle) => {
				if (fEle.id === eleId) {
					return {
						...fEle,
						title: value,
					};
				} else return fEle;
			})
		);
	});

	const handleAddFeature = (type, eleId) => {
		setLoadModal(true);
		let newFeature;
		if (type === "text") {
			newFeature = {
				id: Math.random(),
				sequence: "",
				name: "new_feature",
				title: "",
				support_text: "",
				type: "text",
				lite: "",
				standard: "",
				pro: "",
				enterprise: "",
			};
		} else {
			newFeature = {
				id: Math.random(),
				sequence: "",
				name: "new_feature",
				title: "",
				support_text: "",
				type: "check",
				lite: false,
				standard: false,
				pro: false,
				enterprise: false,
			};
		}
		setData((prev) =>
			prev.map((fEle) => {
				if (fEle.id === eleId) {
					return {
						...fEle,
						features: [
							...fEle.features,
							{...newFeature, sequence: fEle.features.length},
						],
					};
				} else return fEle;
			})
		);
		setTimeout(() => {
			setLoadModal(false);
		}, 100);
	};

	const handleAddSection = (action, index) => {
		setLoadModal(true);
		if (action === "before") {
			data.splice(index, 0, {...newSectionObject, sequence: index + 1});
		} else {
			data.splice(index + 1, 0, {...newSectionObject, sequence: index + 1});
		}
		setData([...data]);
		setTimeout(() => {
			setLoadModal(false);
		}, 100);
	};

	const handleDeleteSection = (eIndex) => {
		setLoadModal(true);
		data.splice(0, eIndex + 1);
		setData([...data]);
		setTimeout(() => {
			setLoadModal(false);
		}, 100);
	};

	const handleDeleteFeature = (eleId, itemId) => {
		setLoadModal(true);
		setData((prev) =>
			prev.map((ele) => {
				if (ele.id === eleId) {
					return {
						...ele,
						features: ele.features.filter((item) => item.id !== itemId),
					};
				} else return ele;
			})
		);
		setTimeout(() => {
			setLoadModal(false);
		}, 200);
	};

	const handleSubmit = () => {
		setLoadModal(true);
		setTimeout(() => {
			const payload = {
				payload: {
					features: data,
				},
			};
			updateComparisonDataApi(payload)
				.then((res) => {
					setSnackbarState({
						open: true,
						severity: "success",
						message: "Subscription Plan Comparison Updated Successfully.",
					});
					setData(res.Item.features);
					setPlans(res.Item.plans);
					setLoadModal(false);
				})
				.catch((err) => {
					console.error(err);
					setLoadPage("error");
				});
		}, 2000);
	};

	const renderComp = () => {
		switch (loadPage) {
			case "found":
				return (
					<React.Fragment>
						<Box sx={{border: "1px solid #999", m: 2, borderRadius: "8px"}}>
							<Grid container>
								<Grid item xs={12}>
									<Grid
										container
										sx={{
											background: "#E0E0E0",
											borderRadius: "8px 8px 0 0",
											borderBottom: "1px solid #999",
										}}
									>
										<Grid item xs={6}>
											<Box
												sx={{
													display: "flex",
													justifyContent: "center",
													alignItems: "center",
													borderRight: "1px solid #999",
													p: 2,
												}}
											>
												<Typography variant="h6" color="primary">
													Feature List
												</Typography>
											</Box>
										</Grid>
										{plans.map((ele, index) => (
											<Grid item xs={1.35} key={index}>
												<Box
													sx={{
														display: "flex",
														justifyContent: "center",
														alignItems: "center",
														borderRight: "1px solid #999",
														p: 2,
													}}
												>
													<Typography variant="h6" color="primary">
														{ele}
													</Typography>
												</Box>
											</Grid>
										))}
										<Grid item xs={0.6}>
											<Box
												sx={{
													display: "flex",
													justifyContent: "center",
													alignItems: "center",
													p: 2,
												}}
											>
												<Typography variant="h6" color="primary">
													Action
												</Typography>
											</Box>
										</Grid>
									</Grid>
								</Grid>
								{data.map((ele, index) => (
									<React.Fragment key={index}>
										<Grid item xs={12}>
											<Grid container>
												<Grid item xs={6}>
													<Box
														sx={{
															display: "flex",
															justifyContent: "center",
															alignItems: "center",
															p: 2,
														}}
													>
														<TitledTextField
															data={{
																name: ele.title,
																title: "Feature Section Title",
																type: "text",
															}}
															defaultValue={ele.title}
															onChange={(e) => handleChangeMainTitle(e, ele.id)}
															fullWidth={true}
															color="primary"
														/>
													</Box>
												</Grid>
												<Grid item xs={6}>
													<Box
														sx={{
															height: "100%",
															display: "flex",
															justifyContent: "flex-end",
															alignItems: "center",
															p: 2,
														}}
													>
														<Button
															onClick={() => handleDeleteSection(index)}
															variant="outlined"
															size="small"
															startIcon={<Delete />}
															color="error"
														>
															Delete this feature section
														</Button>
														<Button
															aria-describedby={id}
															onClick={(event) => {
																setAnchorEl(event.currentTarget);
																setState({
																	sectionId: ele.id,
																	action: "feature",
																});
															}}
															variant="outlined"
															size="small"
															startIcon={<Add />}
															sx={{ml: 2}}
														>
															Add new feature
														</Button>
													</Box>
												</Grid>
												{ele.features.map((item, iIndex) => (
													<React.Fragment key={iIndex}>
														<Grid item xs={12}>
															<Grid
																container
																sx={{
																	borderTop: "1px solid #999",
																	borderBottom:
																		iIndex + 1 === ele.features.length
																			? "1px solid #999"
																			: "0px",
																}}
															>
																<Grid item xs={6}>
																	<Box
																		sx={{
																			display: "flex",
																			flexDirection: "column",
																			borderRight: "1px solid #999",
																			px: 2,
																			pt: 2,
																			pb: 1.5,
																		}}
																	>
																		<TextField
																			size="small"
																			variant="outlined"
																			label="Feature Name"
																			fullWidth
																			defaultValue={item.title}
																			onChange={(e) =>
																				handleChangeFeature(
																					{
																						name: "title",
																						value: e.target.value,
																					},
																					ele.id,
																					item.id
																				)
																			}
																		/>
																		<TextField
																			size="small"
																			variant="outlined"
																			label="Support Text"
																			fullWidth
																			multiline
																			defaultValue={item.support_text}
																			// value={item.support_text}
																			sx={{
																				mt: 1.5,
																				"& div > textarea": {
																					fontSize: "14px",
																					lineHeight: "18px",
																					height: "auto",
																				},
																			}}
																			onChange={(e) =>
																				handleChangeFeature(
																					{
																						name: "support_text",
																						value: e.target.value,
																					},
																					ele.id,
																					item.id
																				)
																			}
																		/>
																	</Box>
																</Grid>
																{plans.map((iItem, iIIndex) => (
																	<Grid item xs={1.35} key={iIIndex}>
																		<Box
																			sx={{
																				height: "100%",
																				display: "flex",
																				justifyContent: "center",
																				alignItems: "center",
																				borderRight: "1px solid #999",
																				p: 1.5,
																			}}
																		>
																			{item.type === "text" ? (
																				<TextField
																					size="small"
																					variant="outlined"
																					fullWidth
																					name={iItem.toLowerCase()}
																					defaultValue={
																						item?.[iItem.toLowerCase()]
																					}
																					onChange={(e) =>
																						handleChangeFeature(
																							{
																								name: e.target.name,
																								value: e.target.value,
																							},
																							ele.id,
																							item.id
																						)
																					}
																				/>
																			) : (
																				<FormControl variant="standard">
																					<FormControlLabel
																						sx={{mr: 0}}
																						control={
																							<Checkbox
																								checked={
																									item?.[iItem.toLowerCase()] ||
																									false
																								}
																								onChange={(e) =>
																									handleChangeFeatureCheck(
																										{
																											name: e.target.name,
																											value: e.target.checked,
																										},
																										ele.id,
																										item.id
																									)
																								}
																								name={iItem.toLowerCase()}
																							/>
																						}
																					/>
																				</FormControl>
																			)}
																		</Box>
																	</Grid>
																))}
																<Grid item xs={0.6}>
																	<Box
																		sx={{
																			height: "100%",
																			display: "flex",
																			justifyContent: "center",
																			alignItems: "center",
																		}}
																	>
																		<IconButton
																			color="error"
																			onClick={() =>
																				handleDeleteFeature(ele.id, item.id)
																			}
																		>
																			<Delete />
																		</IconButton>
																	</Box>
																</Grid>
															</Grid>
														</Grid>
													</React.Fragment>
												))}
											</Grid>
										</Grid>
										<Grid item xs={12}>
											<Box
												sx={{
													display: "flex",
													justifyContent: "flex-end",
													alignItems: "center",
													p: 1.5,
													borderBottom:
														index + 1 === data.length
															? "0px"
															: "1px solid #999",
												}}
											>
												<Button
													variant="outlined"
													size="small"
													startIcon={<Add />}
													onClick={(event) => {
														setAnchorEl(event.currentTarget);
														setState((prev) => ({
															...prev,
															action: "section",
															sectionIndex: index,
														}));
													}}
												>
													Add New Section
												</Button>
											</Box>
										</Grid>
									</React.Fragment>
								))}
							</Grid>
							<Popover
								id={id}
								open={open}
								anchorEl={anchorEl}
								onClose={() => setAnchorEl(null)}
								anchorOrigin={{
									vertical: "bottom",
									horizontal: "left",
								}}
							>
								{state.action === "section" ? (
									<Box
										sx={{
											display: "flex",
											flexDirection: "column",
											p: 1,
										}}
									>
										<Button
											variant="outlined"
											size="small"
											onClick={() => {
												handleAddSection("before", state.sectionIndex);
												setAnchorEl(null);
											}}
										>
											Before this section
										</Button>
										<Button
											variant="outlined"
											size="small"
											sx={{mt: 1}}
											onClick={() => {
												handleAddSection("after", state.sectionIndex);
												setAnchorEl(null);
											}}
										>
											After this section
										</Button>
									</Box>
								) : (
									<Box
										sx={{
											display: "flex",
											flexDirection: "column",
											p: 1,
										}}
									>
										<Button
											variant="outlined"
											size="small"
											onClick={() => {
												handleAddFeature("text", state.sectionId);
												setAnchorEl(null);
											}}
										>
											Text Fields
										</Button>
										<Button
											variant="outlined"
											size="small"
											sx={{mt: 1}}
											onClick={() => {
												handleAddFeature("check", state.sectionId);
												setAnchorEl(null);
											}}
										>
											Checkbox Fields
										</Button>
									</Box>
								)}
							</Popover>
						</Box>
						<Box sx={{textAlign: "right", width: "100%", p: 2}}>
							<Button
								variant="contained"
								sx={{width: "200px"}}
								onClick={handleSubmit}
							>
								Save Changes
							</Button>
						</Box>
					</React.Fragment>
				);
			case "error":
				return <Error />;
			case "noData":
				return (
					<NoData
						createBtn={false}
						displayTitle="Subscription plan comparison"
					/>
				);
			case "load":
				return <CircularProgressComp />;
			default:
				return <CircularProgressComp />;
		}
	};

	return (
		<Page title="HotQR | Plans | Comparison">
			<PageHeader
				title="Manage Subscription Plan Comparison"
				subTitle="Manage all the subscription plans comparison"
				goBack={true}
			/>
			{renderComp()}
			<LoadModal open={loadModal} bgTrans={true} />
			<SnackbarComp
				snackbarState={snackbarState}
				handleClose={() => setSnackbarState((prev) => ({...prev, open: false}))}
			/>
		</Page>
	);
}

export default PlanComparison;

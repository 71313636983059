import urls from "./Urls";
import {executeGet} from "./Methods/iwant-rest-generic";

export const getContentsApi = () => {
	let url = urls.QRCONTENT.FINDALL;
	let actionCode = "";
	let payload = {};
	let method = "GET";
	return executeGet(url, actionCode, payload, method);
};

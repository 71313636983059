import {useRoutes} from "react-router-dom";

import Layout from "./Layout";

import Login from "./Pages/Session/Login";

// Dashboard Components //
import Dashboard from "./Pages/Dashboard";
// Plans Components //
import Plans from "./Pages/Plans";
import CreatePlan from "./Pages/Plans/Create";
import ManagePlan from "./Pages/Plans/Manage";
import PlanComparison from "./Pages/Plans/PlanComparison";
// Contents Componets //
import Contents from "./Pages/Contents";
// Categories Components //
import Categories from "./Pages/Categories";
// User Components //
import Users from "./Pages/Users";
import UserView from "./Pages/Users/View";
import UserQrCodesList from "./Pages/Users/QrCodesList";
import UserQrCodesView from "./Pages/Users/QrCodesView";

export default function Router() {
	return useRoutes([
		{
			path: "/",
			element: <Layout />,
			children: [
				{index: true, element: <Dashboard />},
				{
					path: "users",
					children: [
						{index: true, element: <Users />},
						{path: "view/:userId", element: <UserView />},
						{path: "qrcode/list/:systemUserId", element: <UserQrCodesList />},
						{path: "qrcode/view/:qrId", element: <UserQrCodesView />},
					],
				},
				{
					path: "plans",
					children: [
						{index: true, element: <Plans />},
						{path: "create", element: <CreatePlan />},
						{path: "manage/:planId", element: <ManagePlan />},
						{path: "comparison", element: <PlanComparison />},
					],
				},
				{path: "contents", element: <Contents />},
				{path: "categories", element: <Categories />},
			],
		},
		{path: "/login", element: <Login />},
	]);
}

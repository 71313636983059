import React from "react";
import {
	Box,
	Typography,
	Switch,
	FormControl,
	FormControlLabel,
} from "@mui/material";
import {styled} from "@mui/system";

const AntSwitch = styled(Switch)(({theme}) => ({
	width: 28,
	height: 16,
	padding: 0,
	display: "flex",
	"&:active": {
		"& .MuiSwitch-thumb": {
			width: 15,
		},
		"& .MuiSwitch-switchBase.Mui-checked": {
			transform: "translateX(9px)",
		},
	},
	"& .MuiSwitch-switchBase": {
		padding: 2,
		"&.Mui-checked": {
			transform: "translateX(12px)",
			color: "#fff",
			"& + .MuiSwitch-track": {
				opacity: 1,
				backgroundColor: theme.palette.primary.main,
			},
		},
	},
	"& .MuiSwitch-thumb": {
		boxShadow: "0 2px 4px 0 rgb(0 35 11 / 20%)",
		width: 12,
		height: 12,
		borderRadius: 6,
		transition: theme.transitions.create(["width"], {
			duration: 200,
		}),
	},
	"& .MuiSwitch-track": {
		borderRadius: 16 / 2,
		opacity: 1,
		backgroundColor: "rgba(0,0,0,.25)",
		boxSizing: "border-box",
	},
}));

function SwitchComp({onChange, checked, title}) {
	return (
		<React.Fragment>
			<Typography
				sx={{
					mb: 1,
					fontSize: "16px",
					fontWeight: 600,
					color: "#666",
				}}
			>
				{title}
			</Typography>
			<Box sx={{display: "flex", alignItems: "center"}}>
				<Typography
					sx={{
						fontSize: "16px",
						fontWeight: 600,
						color: "#666",
						mr: "15px",
					}}
				>
					No
				</Typography>
				<FormControl variant="standard">
					<FormControlLabel
						control={<AntSwitch checked={checked} onChange={onChange} />}
					/>
				</FormControl>
				<Typography
					sx={{
						ml: "-10px",
						fontSize: "16px",
						fontWeight: 600,
						color: "#666",
					}}
				>
					Yes
				</Typography>
			</Box>
		</React.Fragment>
	);
}

export default SwitchComp;

import React from "react";

import TableComp from "./Components/Table";
import TableContext from "./Context";
import Snackbar from "./Components/Snackbar";

export default function Table(props) {
	const {
		stickyHeader = false,
		srNumber = false,
		headerProps,
		tableProps,
		dataItems = false,
		headerArray = false,
		displayTitle = "item",
		loadPage = "NoData",
		pagination = false,
		paginationState,
		handleChangePage,
		handleChangeRowsPerPage,
		handleViewEdit,
		handleCreate,
		handleEdit,
		handleView,
		handleDelete,
		handleDownload,
	} = props;
	const [snackbarState, setSnackbarState] = React.useState({open: false});
	return (
		<TableContext.Provider
			value={{
				stickyHeader: stickyHeader,
				srNumber: srNumber,
				headerProps: headerProps,
				tableProps: tableProps,
				dataItems: dataItems,
				headerArray: headerArray,
				displayTitle: displayTitle,
				loadPage: loadPage,
				pagination: pagination,
				paginationState: paginationState
					? paginationState
					: {totalCount: 0, page: 0, rowsPerPage: 15},
				handleChangePage: handleChangePage
					? handleChangePage
					: () =>
							setSnackbarState({
								open: true,
								message:
									"Table page change but function not found! (Table Pagination)",
							}),
				handleChangeRowsPerPage: handleChangeRowsPerPage
					? handleChangeRowsPerPage
					: () =>
							setSnackbarState({
								open: true,
								message:
									"Table rows per page change but function not found! (Table Pagination)",
							}),
				handleCreate: handleCreate
					? handleCreate
					: () =>
							setSnackbarState({
								open: true,
								message:
									"Create new item button clicked but function not found! (Table Action)",
							}),
				handleViewEdit: handleViewEdit
					? handleViewEdit
					: () =>
							setSnackbarState({
								open: true,
								message:
									"Edit item button clicked but function not found! (Table Action)",
							}),
				handleEdit: handleEdit
					? handleEdit
					: () =>
							setSnackbarState({
								open: true,
								message:
									"View/Edit item button clicked but function not found! (Table Action)",
							}),
				handleView: handleView
					? handleView
					: () =>
							setSnackbarState({
								open: true,
								message:
									"View item button clicked but function not found! (Table Action)",
							}),
				handleDelete: handleDelete
					? handleDelete
					: () =>
							setSnackbarState({
								open: true,
								message:
									"Delete item button clicked but function not found! (Table Action)",
							}),
				handleDownload: handleDownload
					? handleDownload
					: () =>
							setSnackbarState({
								open: true,
								message:
									"Download button clicked but function not found! (Table Action)",
							}),
			}}
		>
			<TableComp />
			<Snackbar
				snackbarState={snackbarState}
				handleClose={() => setSnackbarState({open: false})}
			/>
		</TableContext.Provider>
	);
}

import React from "react";
import {Outlet, useNavigate} from "react-router";
import {Hidden} from "@mui/material";
import {styled} from "@mui/system";

import Drawer from "./Drawer";

import Logout from "../Pages/Session/Logout";
import AuthContext from "../Context/AuthContext";

function Layout(props) {
	const navigate = useNavigate();

	const drawerWidth = 250;

	const {logoutModal} = React.useContext(AuthContext);

	const [drawerOpen, setDrawerOpen] = React.useState(false);

	const handleDrawerToggle = () => {
		setDrawerOpen(!drawerOpen);
	};

	React.useEffect(() => {
		if (!localStorage.getItem("token")) {
			navigate("/login");
		}
	});

	const LayoutRoot = styled("div")(({theme}) => ({
		backgroundColor: "#FFF",
		display: "flex",
		height: "100%",
		overflow: "hidden",
		width: "100%",
	}));

	const LayoutWrapper = styled("div")(({theme}) => ({
		display: "flex",
		flex: "1 1 auto",
		overflow: "hidden",
		[theme.breakpoints.up("lg")]: {
			paddingLeft: drawerWidth,
		},
	}));

	const LayoutContainer = styled("div")({
		display: "flex",
		flex: "1 1 auto",
		overflow: "hidden",
	});

	const LayoutContent = styled("div")({
		flex: "1 1 auto",
		height: "100%",
		overflow: "auto",
		padding: "20px",
	});

	return (
		<React.Fragment>
			<LayoutRoot>
				<Hidden lgUp implementation="js">
					<Drawer
						open={drawerOpen}
						onClose={handleDrawerToggle}
						onClick={handleDrawerToggle}
						variant="temporary"
						PaperProps={{
							sx: {
								width: drawerWidth,
							},
						}}
					/>
				</Hidden>
				<Hidden lgDown implementation="css">
					<Drawer
						variant="persistent"
						PaperProps={{
							sx: {
								width: drawerWidth,
							},
						}}
					/>
				</Hidden>
				<LayoutWrapper>
					<LayoutContainer>
						<LayoutContent>
							<Outlet />
						</LayoutContent>
					</LayoutContainer>
				</LayoutWrapper>
			</LayoutRoot>
			{logoutModal && <Logout />}
		</React.Fragment>
	);
}

export default Layout;

import React from "react";

import SnackbarComp from "../../Components/Snackbar";

import {getContentsApi} from "../../Services/Contents";
import Page from "../../Components/Page";
import PageHeader from "../../Components/PageHeader";
import {useNavigate} from "react-router";
import DeleteDialog from "../../Components/DeleteDialog";
import Table from "../../Components/Table";
import Filter from "../../Components/Filter";

const headerArray = [
	{
		title: "Name",
		name: "title",
		width: 700,
		align: "left",
	},
	{
		title: "Created On",
		name: "createdOn",
		width: 600,
		align: "center",
	},
	// {
	// 	title: "Actions",
	// 	name: "action",
	// 	actions: ["view/edit", "delete"],
	// 	width: 200,
	// 	align: "center",
	// },
];

export default function Contents(props) {
	const navigate = useNavigate();

	const [loadPage, setLoadPage] = React.useState("loading");
	const [dataItems, setDataItems] = React.useState();
	const [count, setCount] = React.useState(0);
	const [page, setPage] = React.useState(0);
	const [rowsPerPage, setRowsPerPage] = React.useState(10);
	const [deleteDialog, setDeleteDialog] = React.useState({open: false});
	const [snackbarState, setSnackbarState] = React.useState({
		open: false,
		message: "",
		severity: "success",
	});

	const getContentsApiCall = React.useCallback(() => {
		getContentsApi()
			.then((res) => {
				setDataItems(res.Items);
				if (res.Items.length > 0) {
					setLoadPage("found");
				} else {
					setLoadPage("noData");
				}
			})
			.catch((err) => {
				setLoadPage("error");
				console.error(err);
			});
	}, []);

	React.useEffect(() => {
		getContentsApiCall();
	}, [getContentsApiCall]);

	const handleChangePage = (event, pageNumber) => {
		setPage(pageNumber);
	};

	const handleChangeRowsPerPage = (event) => {
		setRowsPerPage(event.target.value);
	};

	const handleCreate = () => {
		navigate("/contents/create");
	};

	const handleViewEdit = () => {
		alert("View/Edit QR Content");
	};

	const handleOpenDeleteDialog = () => {
		setDeleteDialog({open: true});
	};

	const handleDelete = () => {
		setDeleteDialog({open: true, loading: true});
		alert("Delete QR Content");
	};

	const handleCancel = () => {
		setDeleteDialog({open: false});
	};

	const handleChangeFilter = (filter, name, value) => {};

	return (
		<Page title="HotQR | Contents">
			<PageHeader
				title="QR Contents"
				subTitle="Manage qr contents which is used to create qr code"
				handleCreate={handleCreate}
				displayTitle={"Content"}
			/>
			<Filter
				search={true}
				searchProps={{name: "keywords", label: "Search items"}}
				handleChange={handleChangeFilter}
			/>
			<Table
				tableProps={{
					maxHeight: "79vh",
				}}
				srNumber={true}
				stickyHeader={true}
				loadPage={loadPage}
				dataItems={dataItems}
				pagination={false}
				headerArray={headerArray}
				displayTitle={"Content"}
				// handleChangePage={handleChangePage}
				// handleChangeRowsPerPage={handleChangeRowsPerPage}
				// handleCreate={handleCreate}
				// handleViewEdit={handleViewEdit}
				// handleDelete={handleOpenDeleteDialog}
			/>
			<DeleteDialog
				state={deleteDialog}
				handleDelete={handleDelete}
				handleCancel={handleCancel}
			/>
			<SnackbarComp
				snackbarState={snackbarState}
				handleClose={() => setSnackbarState((prev) => ({...prev, open: false}))}
			/>
		</Page>
	);
}

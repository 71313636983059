import React from "react";
import {useParams} from "react-router";
import {Box, Button} from "@mui/material";

import Page from "../../Components/Page";
import PageHeader from "../../Components/PageHeader";
import CircularProgressComp from "../../Components/CircularProgress";
import NoData from "../../Components/NoData";
import Error from "../../Components/Error";

import Features from "./Components/Features";
import FieldsComp from "./Components/FieldsComp";

import {getPlanByIdApi, updatePlanById} from "../../Services/Plans";
import LoadModal from "../../Components/LoadModal";
import SnackbarComp from "../../Components/Snackbar";

function ManagePlan() {
	const {planId} = useParams();

	const [loadPage, setLoadPage] = React.useState("load");
	const [loadModal, setLoadModal] = React.useState(false);
	const [snackbarState, setSnackbarState] = React.useState({
		open: false,
		severity: "info",
		message: "section",
	});

	const [state, setState] = React.useState({
		title: "",
		lifetime_cost_switch: false,
		lifetime_cost: "",
		monthly_cost: "",
		annual_cost: "",
		total_year_cost: "",
		qrcode_limit: "",
		qrcode_scan_limit_switch: false,
		qrcode_scan_limit: "",
	});

	const [featuresState, setFeaturesState] = React.useState([
		{
			id: Math.random(),
			feature_name: "",
			support_text: "",
		},
	]);

	const setStateDataFun = React.useCallback((item) => {
		if (item.features) {
			setFeaturesState(item.features);
		}
		let data = {
			...item,
			lifetime_cost_switch: false,
			qrCode_scan_limit_switch: false,
		};
		if (item.qrcode_scan_limit === "max" || item.qrcode_scan_limit === false) {
			data.qrcode_scan_limit_switch = false;
		} else {
			data.qrcode_scan_limit_switch = true;
		}
		if (item.lifetime_cost) {
			data.lifetime_cost_switch = true;
		} else {
			data.lifetime_cost_switch = false;
		}
		setState(data);
		setLoadPage("found");
		setLoadModal(false);
	}, []);

	const getPlanByIdApiCall = React.useCallback(() => {
		setLoadPage("load");
		getPlanByIdApi(planId)
			.then((res) => {
				if (res.Item) {
					setStateDataFun(res.Item);
				} else {
					setLoadPage("noData");
				}
			})
			.catch((err) => {
				console.error(err);
				setLoadPage("error");
			});
	}, [planId, setStateDataFun]);

	React.useEffect(() => {
		getPlanByIdApiCall();
	}, [getPlanByIdApiCall]);

	const handleSubmit = () => {
		setLoadModal(true);
		let newState = state;
		if (state.qrcode_scan_limit_switch) {
			delete newState.qrcode_scan_limit_switch;
		} else {
			newState.qrcode_scan_limit = "max";
			delete newState.qrcode_scan_limit_switch;
		}
		if (state.lifetime_cost_switch) {
			delete newState.lifetime_cost_switch;
		} else {
			newState.lifetime_cost = false;
			delete newState.lifetime_cost_switch;
		}
		newState.total_year_cost = state.annual_cost * 12;
		newState.annual_cost = parseInt(state.annual_cost);
		newState.monthly_cost = parseInt(state.monthly_cost);
		const newFeaturesState =
			featuresState.length > 0
				? featuresState
						?.map((ele) => {
							if (
								ele.feature_name === "" ||
								ele.feature_name === null ||
								ele.feature_name === undefined
							)
								return null;
							else return ele;
						})
						.filter((item) => item !== null)
				: [];
		const payload = {
			payload: {
				...newState,
				features: newFeaturesState,
			},
		};
		console.log(payload);

		updatePlanById(payload, planId)
			.then((res) => {
				setSnackbarState({
					open: true,
					severity: "success",
					message: "Subscription Plan Updated Successfully.",
				});
				setStateDataFun(res.Item);
				window.scrollTo({
					top: 0,
					behavior: "smooth",
				});
			})
			.catch((err) => console.error(err));
	};

	const renderComp = () => {
		switch (loadPage) {
			case "found":
				return (
					<React.Fragment>
						<FieldsComp state={state} setState={setState} />
						<Features
							type="basic"
							featuresState={featuresState}
							setFeaturesState={setFeaturesState}
						/>
						<Box sx={{px: 2, mt: 3, textAlign: "right"}}>
							<Button
								onClick={handleSubmit}
								variant="contained"
								color="primary"
								sx={{width: "150px"}}
							>
								Update Plan
							</Button>
						</Box>
					</React.Fragment>
				);
			case "load":
				return <CircularProgressComp />;
			case "noData":
				return <NoData displayTitle="Subscription Plan" createBtn={false} />;
			case "error":
				return <Error />;
			default:
				return <CircularProgressComp />;
		}
	};

	return (
		<Page title="HotQR | Plan | Manage">
			<PageHeader
				title="View and Update Subscription Plan"
				subTitle="View and update subscription plan."
				goBack={true}
			/>
			{renderComp()}
			<LoadModal open={loadModal} text="Updating plan..." />
			<SnackbarComp
				snackbarState={snackbarState}
				handleClose={() => setSnackbarState({open: false})}
			/>
		</Page>
	);
}

export default ManagePlan;

import React from "react";
import PropTypes from "prop-types";
import {useNavigate, useLocation} from "react-router";
import {Button, ListItem, Typography} from "@mui/material";
import AuthContext from "../../Context/AuthContext";

const NavItem = (props) => {
	const {setLogoutModal} = React.useContext(AuthContext);
	const navigate = useNavigate();
	const location = useLocation();
	const goTo = (link) => {
		if (link) {
			navigate(link);
		}
		localStorage.setItem("currentItem", props.title);
	};
	const {title, icon: Icon, link, tag, active, staticContext, ...rest} = props;

	const url = location.pathname;
	const url1 = url?.split("/")?.[1];
	const activeItem = url1 === tag;

	return (
		<ListItem
			disableGutters
			sx={{
				display: "flex",
				py: 0,
			}}
			{...rest}
		>
			<Button
				sx={{
					color: "#FFFFFF",
					fontWeight: "medium",
					justifyContent: "flex-start",
					letterSpacing: 0,
					py: 1.2,
					paddingLeft: "15px",
					textTransform: "none",
					width: "100%",
					...(activeItem && {
						backdropFilter: "brightness(70%)",
					}),
					"& svg": {
						mr: 1,
					},
				}}
				onClick={() => {
					if (title === "Logout") {
						setLogoutModal(true);
					} else {
						goTo(link);
					}
				}}
			>
				{Icon && <Icon size="20" />}
				<Typography style={{fontSize: "20px"}}>{title}</Typography>
			</Button>
		</ListItem>
	);
};

NavItem.propTypes = {
	link: PropTypes.string,
	icon: PropTypes.elementType.isRequired,
	title: PropTypes.string.isRequired,
};

export default NavItem;

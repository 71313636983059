import {Add} from "@mui/icons-material";
import {Box, Button, Grid, TextField, Typography} from "@mui/material";
import React from "react";
import TitledTextField from "./TitledTextField";

function Features({type, featuresState, setFeaturesState}) {
	return (
		<Box
			sx={{
				mt: 2,
				p: 3,
				mx: 2,
				background: "#FFF",
				border: "1px solid #999",
				borderRadius: "12px",
			}}
		>
			<Typography sx={{mb: 2}} color="primary" variant="h5">
				Plan Features
			</Typography>
			<Grid container spacing={2}>
				{featuresState?.map((ele, index) => (
					<Grid item xs={12} key={index}>
						<Box
							sx={{
								pt: 1.5,
								px: 2,
								pb: 2,
								background: "#FFF",
								border: "1px solid #999",
								borderRadius: "12px",
							}}
						>
							<Grid container spacing={1}>
								<Grid item xs={12}>
									<Box
										sx={{
											display: "flex",
											justifyContent: "space-between",
											alignItems: "center",
										}}
									>
										<Typography
											sx={{
												fontSize: "16px",
												fontWeight: 600,
												color: "#666",
											}}
										>
											Feature {index + 1}
										</Typography>
										<Button
											size="small"
											onClick={() =>
												setFeaturesState((prev) =>
													prev.filter((item) => item.id !== ele.id)
												)
											}
										>
											Remove Feature
										</Button>
									</Box>
								</Grid>
								<Grid item xs={12}>
									<TitledTextField
										data={{
											title: "Feature name",
											name: "feature_name",
										}}
										fontSize="14px"
										value={ele.feature_name}
										onChange={(e) =>
											setFeaturesState((prev) =>
												prev.map((item) => {
													if (item.id === ele.id) {
														return {
															...item,
															feature_name: e.target.value,
														};
													} else return item;
												})
											)
										}
									/>
								</Grid>
								<Grid item xs={12}>
									<Box sx={{display: "flex", flexDirection: "column"}}>
										<Typography
											sx={{
												mb: 0.2,
												fontSize: "14px",
												fontWeight: 600,
												color: "#666",
											}}
										>
											Support Text
										</Typography>
										<TextField
											name="support_text"
											type="text"
											size="small"
											fullWidth
											color="primary"
											placeholder={"Write here..."}
											value={ele.support_text}
											multiline
											minRows={2}
											onChange={(e) =>
												setFeaturesState((prev) =>
													prev.map((item) => {
														if (item.id === ele.id) {
															return {
																...item,
																support_text: e.target.value,
															};
														} else return item;
													})
												)
											}
										/>
									</Box>
								</Grid>
							</Grid>
						</Box>
					</Grid>
				))}
				<Grid item xs={12} sx={{textAlign: "right"}}>
					<Button
						startIcon={<Add />}
						variant="outlined"
						onClick={() => {
							setFeaturesState((prev) => [
								...prev,
								{
									id: Math.random(),
									feature_name: "",
									support_text: "",
								},
							]);
						}}
					>
						Add Feature
					</Button>
				</Grid>
			</Grid>
		</Box>
	);
}

export default Features;

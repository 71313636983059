import React from "react";
import {
	Box,
	Container,
	Button,
	Typography,
	Grid,
	TextField,
	InputAdornment,
	IconButton,
} from "@mui/material";
import {Helmet} from "react-helmet-async";
import {useNavigate} from "react-router";
import axios from "axios";

import qrBackground from "../../Assets/Login/qr1.svg";
import hotqr from "../../Assets/HOTQr.png";

import Page from "../../Components/Page";
import SnackbarComp from "../../Components/Snackbar";

import urls from "../../Services/Urls";
import {VisibilityOffOutlined, VisibilityOutlined} from "@mui/icons-material";

function Login(props) {
	const navigate = useNavigate();
	const [state, setState] = React.useState({
		username: "",
		password: "",
		error: false,
		visible: false,
	});
	const [snackbarState, setSnackbarState] = React.useState({
		open: false,
		message: "",
		severity: "",
	});

	React.useEffect(() => {
		if (localStorage.getItem("token")) {
			navigate("/");
		}
	});

	const handleChange = (e) => {
		const {value, name} = e.target;
		setState((prev) => ({...prev, [name]: value}));
	};

	const handleSubmit = (e) => {
		e.preventDefault();
		setState((prev) => ({...prev, error: false, visible: false}));
		const {username, password} = state;
		const formData = new FormData();
		formData.append("domain", "hotqr");
		formData.append("username", username);
		formData.append("password", password);
		formData.append("accountType", -1);
		formData.append("passwordType", "password");
		formData.append("grant_type", "password");
		formData.append("tid", Date.now() + 30000);

		axios
			.post(urls.LOGIN, formData, {
				headers: {
					"Content-Type": "multipart/form-data",
					Authorization: "Basic aXRwbDppd2FudHVubGltaXRlZA==",
				},
			})
			.then((response) => {
				const {access_token} = response && response.data ? response.data : null;
				localStorage.setItem("token", access_token);
				setSnackbarState({
					open: true,
					severity: "success",
					message: "Login successfull...",
				});
				navigate("/");
			})
			.catch((error) => {
				console.error(error);
				if (error.response.status === 400) {
					setState((prev) => ({
						...prev,
						error: "Please enter correct username or password.",
					}));
					setSnackbarState({
						open: true,
						severity: "error",
						message: "Please enter correct username or password.",
					});
				} else if (error.response.status === 404) {
					setState((prev) => ({
						...prev,
						error: "The requested URL/badpage was not found on this server.",
					}));
					setSnackbarState({
						open: true,
						severity: "error",
						message: "The requested URL/badpage was not found on this server.",
					});
				} else if (error.response.status === 500) {
					setState((prev) => ({...prev, error: "Oops, Something Went Wrong!"}));
					setSnackbarState({
						open: true,
						severity: "error",
						message: "Oops, Something Went Wrong!",
					});
				}
			});
	};

	return (
		<React.Fragment>
			<Helmet>
				<title>HotQR | Admin</title>
			</Helmet>
			<Box sx={{bgcolor: "primary.main"}}>
				<Container
					maxWidth={window.screen.width > 1440 ? "xl" : "lg"}
					style={{position: "relative", height: "100vh"}}
				>
					<Box
						sx={{
							bgcolor: "#FFF",
							position: "absolute",
							top: "50%",
							left: "50%",
							transform: "translate(-50%, -50%)",
							borderRadius: "12px",
							pt: 3,
							pb: 6,
							px: 3,
						}}
					>
						<Grid container spacing={2}>
							<Grid item xs={12}>
								<img
									alt=""
									src={hotqr}
									style={{height: "80px", width: "120px"}}
								/>
							</Grid>
							<Grid item xs={12} sx={{textAlign: "center"}}>
								<Typography variant="h3" sx={{color: "#444"}}>
									WELCOME ADMIN
								</Typography>
							</Grid>
							<Grid item xs={12} sx={{textAlign: "center"}}>
								<Typography variant="h5" sx={{color: "#444"}}>
									Login
								</Typography>
							</Grid>
							<Grid item xs={12}>
								<form
									onSubmit={handleSubmit}
									style={{
										display: "flex",
										flexDirection: "column",
										flexWrap: "wrap",
										justifyContent: "center",
										alignItems: "center",
									}}
									autocomplete="off"
								>
									<TextField
										type="text"
										name="username"
										variant="outlined"
										label="Username"
										color="primary"
										onChange={handleChange}
										value={state.username}
										sx={{
											width: "400px",
											mt: 2,
										}}
										id="nrew"
									/>
									<TextField
										type={state.visible ? "text" : "password"}
										name="password"
										variant="outlined"
										label="Password"
										color="primary"
										onChange={handleChange}
										value={state.password}
										sx={{
											width: "400px",
											mt: 2,
										}}
										InputProps={{
											endAdornment: (
												<InputAdornment position="end">
													<IconButton
														sx={{p: 0}}
														onClick={() => {
															// if(state.visible){
															setState((prev) => ({
																...prev,
																visible: !state.visible,
															}));
															// }
														}}
													>
														{state.visible ? (
															<VisibilityOffOutlined />
														) : (
															<VisibilityOutlined />
														)}
													</IconButton>
												</InputAdornment>
											),
										}}
									/>
									{state.error && (
										<Typography color="error" sx={{mt: 1}}>
											{state.error}
										</Typography>
									)}
									<Button
										type="submit"
										variant="contained"
										color="primary"
										sx={{mt: 2, width: "400px"}}
									>
										Submit
									</Button>
								</form>
							</Grid>
						</Grid>
					</Box>
				</Container>
			</Box>
			<SnackbarComp
				snackbarState={snackbarState}
				handleClose={() => setSnackbarState({open: false})}
			/>
		</React.Fragment>
	);
}

export default Login;

import urls from "./Urls";
import {executeGet} from "./Methods/iwant-rest-generic";

export const getDashboardApi = () => {
	let url = urls.DASHBOARD.GET_DASHBOARD;
	let actionCode = "";
	let payload = {};
	let method = "GET";
	return executeGet(url, actionCode, payload, method);
};

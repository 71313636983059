import React from "react";
import {useNavigate} from "react-router";
import {Box, Button, Grid, Typography} from "@mui/material";

import Page from "../../Components/Page";
import PageHeader from "../../Components/PageHeader";
import {getAllPlansApi} from "../../Services/Plans";
import CircularProgressComp from "../../Components/CircularProgress";
import NoData from "../../Components/NoData";
import Error from "../../Components/Error";

const dataArray = [
	{
		title: "Monthly Cost",
		key: "monthly_cost",
	},
	{
		title: "Annual Cost (Monthly Cost)",
		key: "annual_cost",
	},
	{
		title: "Total Year Cost",
		key: "total_year_cost",
	},
	{
		title: "Lifetime cost",
		key: "lifetime_cost",
	},
	{
		title: "QRCode Creation Limit",
		key: "qrcode_limit",
	},
	{
		title: "QRCode Scan Limit",
		key: "qrcode_scan_limit",
	},
];

const keyTypoStyle = {
	fontSize: "16px",
	letterSpacing: "0.7px",
	width: "280px",
	fontWeight: 600,
};

const valueTypoStyle = {
	fontSize: "16px",
	letterSpacing: "0.8px",
	fontWeight: 600,
};

function Plans(props) {
	const navigate = useNavigate();

	const [data, setData] = React.useState();
	const [loadPage, setLoadPage] = React.useState("load");

	// const handleCreate = () => {
	// 	navigate("/plans/create");
	// };

	const getAllPlansApiCall = React.useCallback(() => {
		setLoadPage("load");
		getAllPlansApi()
			.then((res) => {
				if (res.Count > 0) {
					setLoadPage("found");
				} else {
					setLoadPage("noData");
				}
				setData(res.Items);
			})
			.catch((err) => {
				console.error(err);
				setLoadPage("error");
			});
	}, []);

	React.useEffect(() => {
		getAllPlansApiCall();
	}, [getAllPlansApiCall]);

	const handleGoManage = (planId) => {
		navigate("/plans/manage/" + planId);
	};
	const handleGoComparison = (planId) => {
		navigate("/plans/comparison");
	};

	function dynamicSort(property) {
		var sortOrder = 1;
		if (property[0] === "-") {
			sortOrder = -1;
			property = property.substr(1);
		}
		return function (a, b) {
			var result =
				a[property] < b[property] ? -1 : a[property] > b[property] ? 1 : 0;
			return result * sortOrder;
		};
	}

	const renderComp = () => {
		switch (loadPage) {
			case "found":
				return (
					<Box sx={{px: 2}}>
						<Grid container spacing={2}>
							<Grid item xs={12} sx={{textAlign: "right"}}>
								<Button
									variant="contained"
									color="primary"
									onClick={handleGoComparison}
								>
									Manage Plans Comparison Data
								</Button>
							</Grid>
							{data.sort(dynamicSort("sequence")).map((ele, index) => (
								<Grid item lg={6} xl={4} key={index}>
									<Box
										sx={{
											p: 3,
											border: "1px solid #999",
											borderRadius: "12px",
											height: "100%",
										}}
									>
										<Box
											sx={{
												display: "flex",
												justifyContent: "space-between",
												alignItems: "center",
												mb: 1,
											}}
										>
											<Typography color="primary" variant="h6" sx={{mb: 1}}>
												{ele.title}
											</Typography>
											<Button
												variant="outlined"
												onClick={() => handleGoManage(ele.id)}
											>
												View/Edit
											</Button>
										</Box>
										{dataArray.map((item, iIndex) => {
											if (ele.lifetime_cost) {
												if (item.key === "monthly_cost") {
													return (
														<Box
															key={iIndex}
															sx={{
																display: "flex",
																mb: 0.1,
																borderBottom: "1px solid #D0D0D0",
																px: 0,
																py: 0.5,
															}}
														>
															<Typography sx={keyTypoStyle} color="primary">
																Available for lifetime single plan
															</Typography>
															<Typography sx={valueTypoStyle}>Yes</Typography>
														</Box>
													);
												} else if (
													item.key === "annual_cost" ||
													item.key === "total_year_cost"
												) {
													return null;
												} else
													return (
														<Box
															key={iIndex}
															sx={{
																display: "flex",
																mb: 0.1,
																borderBottom: "1px solid #D0D0D0",
																px: 0,
																py: 0.5,
															}}
														>
															<Typography sx={keyTypoStyle} color="primary">
																{item.title + " "}
															</Typography>
															<Typography sx={valueTypoStyle}>
																{ele?.[item.key] === 0
																	? "0"
																	: ele?.[item.key] === false
																	? "0"
																	: ele?.[item.key] === "max"
																	? "Unlimited"
																	: ele?.[item.key] || "<empty>"}
															</Typography>
														</Box>
													);
											} else {
												if (item.key === "lifetime_cost") {
													return null;
												} else
													return (
														<Box
															key={iIndex}
															sx={{
																display: "flex",
																mb: 0.1,
																borderBottom: "1px solid #D0D0D0",
																px: 0,
																py: 0.5,
															}}
														>
															<Typography sx={keyTypoStyle} color="primary">
																{item.title + " "}
															</Typography>
															<Typography sx={valueTypoStyle}>
																{ele?.[item.key] === 0
																	? "0"
																	: ele?.[item.key] === false
																	? "0"
																	: ele?.[item.key] === "max"
																	? "Unlimited"
																	: ele?.[item.key] || "<empty>"}
															</Typography>
														</Box>
													);
											}
										})}
									</Box>
								</Grid>
							))}
						</Grid>
					</Box>
				);
			case "load":
				return <CircularProgressComp />;
			case "noData":
				return <NoData displayTitle="Subscription Plan" createBtn={false} />;
			case "error":
				return <Error />;
			default:
				return <CircularProgressComp />;
		}
	};

	return (
		<Page title="HotQR | Plans">
			<PageHeader
				title="Subscription Plans"
				subTitle="Manage qr code subscription plans."
				// createBtn={true}
				// displayTitle="Plan"
				// handleCreate={handleCreate}
			/>
			{renderComp()}
		</Page>
	);
}

export default Plans;

import React from "react";
import {useNavigate} from "react-router";
import {Container} from "@mui/material";

import {getCategoriesApi} from "../../Services/Categories";

import SnackbarComp from "../../Components/Snackbar";
import Page from "../../Components/Page";
import PageHeader from "../../Components/PageHeader";
import Table from "../../Components/Table";
import DeleteDialog from "../../Components/DeleteDialog";
import Filter from "../../Components/Filter";

const headerArray = [
	{
		title: "Category Name",
		name: "title",
		width: 700,
		align: "left",
	},
	{
		title: "Content Name",
		objName: "content",
		name: "title",
		width: 500,
		align: "left",
	},
	{
		title: "Created On",
		name: "createdOn",
		width: 600,
		align: "center",
	},
];

export default function Categories(props) {
	const navigate = useNavigate();

	const [loadPage, setLoadPage] = React.useState("loading");
	const [dataItems, setDataItems] = React.useState();
	const [deleteDialog, setDeleteDialog] = React.useState({open: false});
	const [snackbarState, setSnackbarState] = React.useState({
		open: false,
		message: "",
		severity: "success",
	});

	const getItemsApiCall = React.useCallback(() => {
		getCategoriesApi()
			.then((res) => {
				setDataItems(res.Items);
				if (res.Items.length > 0) {
					setLoadPage("found");
				} else {
					setLoadPage("notFound");
				}
			})
			.catch((err) => {
				setLoadPage("error");
				console.error(err);
			});
	}, []);

	React.useEffect(() => {
		getItemsApiCall();
	}, [getItemsApiCall]);

	const handleCreate = () => {
		navigate("/categories/create");
	};

	const handleDelete = () => {
		setDeleteDialog({open: true, loading: true});
		alert("Delete QR Content");
	};

	const handleCancel = () => {
		setDeleteDialog({open: false});
	};

	const handleChangeFilter = (filter, name, value) => {};

	return (
		<Page title="HotQR | Categories">
			<PageHeader
				title="QR Categories"
				subTitle="Manage qr categories which is used to create qr code"
				handleCreate={handleCreate}
				displayTitle={"Category"}
			/>
			<Filter
				search={true}
				searchProps={{name: "keywords", label: "Search items"}}
				handleChange={handleChangeFilter}
			/>
			<Table
				tableProps={{
					maxHeight: "79vh",
				}}
				srNumber={true}
				stickyHeader={true}
				loadPage={loadPage}
				dataItems={dataItems}
				pagination={false}
				headerArray={headerArray}
				displayTitle={"Category"}
			/>
			<DeleteDialog
				state={deleteDialog}
				handleDelete={handleDelete}
				handleCancel={handleCancel}
			/>
			<SnackbarComp
				snackbarState={snackbarState}
				handleClose={() => setSnackbarState((prev) => ({...prev, open: false}))}
			/>
		</Page>
	);
}

import React from "react";
import {
	Box,
	Button,
	Card,
	CircularProgress,
	Container,
	Grid,
	Paper,
	Typography,
} from "@mui/material";
import {FiUsers} from "react-icons/fi";
import {ImQrcode} from "react-icons/im";
import {MdOutlineCategory} from "react-icons/md";

import Page from "../../Components/Page";
import {getDashboardApi} from "../../Services/Dashboard";

const dashboardElementsArray = [
	{
		title: "Total Users",
		key: "totalUsers",
		value: false,
		icon: <FiUsers style={{fontSize: "40px"}} />,
	},
	{
		title: "Total QR Codes Generated",
		key: "totalQrCodesGenerated",
		value: false,
		icon: <ImQrcode style={{fontSize: "40px"}} />,
	},
	{
		title: "Total QR Codes Categories",
		key: "totalQrCategories",
		value: false,
		icon: <MdOutlineCategory style={{fontSize: "50px"}} />,
	},
];

export default function Dashboard(props) {
	const [elements, setElements] = React.useState(dashboardElementsArray);

	const getDashboardApiCall = React.useCallback(() => {
		getDashboardApi()
			.then((res) => {
				setElements((prev) =>
					prev.map((ele) => {
						return {
							...ele,
							value: res?.[ele.key],
						};
					})
				);
			})
			.catch((err) => console.error(err));
	}, []);

	React.useEffect(() => {
		console.log("render dashboard");
		getDashboardApiCall();
	}, [getDashboardApiCall]);

	return (
		<Page title="HotQr | Dashboard">
			<Grid container spacing={3}>
				{elements?.map((ele, index) => (
					<Grid item xs={4} key={index}>
						<Paper
							sx={{
								p: 3,
								boxShadow:
									"rgba(0, 0, 0, 0.02) 0px 1px 3px 0px, rgba(27, 31, 35, 0.15) 0px 0px 0px 1px",
								display: "flex",
								alignItems: "center",
								justifyContent: "space-between",
							}}
						>
							<Box>
								<Typography variant="h5" style={{fontSize: "16px"}}>
									{ele.title}
								</Typography>
								<Typography variant="h3" sx={{mt: 1}}>
									{ele.value ? (
										ele.value
									) : (
										<CircularProgress style={{height: "27px", width: "27px"}} />
									)}
								</Typography>
							</Box>
							<Box sx={{color: "primary.main", pr: 2}}>{ele.icon}</Box>
						</Paper>
					</Grid>
				))}
			</Grid>
		</Page>
	);
}

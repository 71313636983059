import React from "react";

const defaultContext = {
	dataItems: [],
	srNumber: false,
	headerArray: [],
	displayTitle: "data",
	loadPage: "NoData",
	pagination: false,
	paginationState: {totalCount: 0, page: 0, rowsPerPage: 15},
	handleChangePage: () => {},
	handleChangeRowsPerPage: () => {},
	handleViewEdit: () => {},
	handleCreate: () => {},
	handleEdit: () => {},
	handleView: () => {},
	handleDelete: () => {},
	handleDownload: () => {},
};

const TableContext = React.createContext(defaultContext);

export default TableContext;

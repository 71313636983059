import {Alert, Snackbar} from "@mui/material";
import PropTypes from "prop-types";
import React from "react";

function SnackbarComp(props) {
	const {
		snackbarState: {
			open = false,
			severity = "warning",
			message = "Snackbar message is empty!",
		} = {},
		handleClose,
	} = props;
	return (
		<Snackbar
			open={open}
			anchorOrigin={{vertical: "top", horizontal: "right"}}
			autoHideDuration={5000}
			onClose={handleClose}
		>
			<Alert onClose={handleClose} severity={severity} sx={{width: "100%"}}>
				{message}
			</Alert>
		</Snackbar>
	);
}

export default SnackbarComp;

SnackbarComp.protoTypes = {
	handleClose: PropTypes.func.isRequired,
	snackbar: PropTypes.object.isRequired,
};

import React from "react";
import AuthContext from "./AuthContext";

function AuthContextProvider({children}) {
	const {logoutModal} = React.useContext(AuthContext);

	const [logoutModalState, setLogoutModalState] = React.useState(logoutModal);

	const handleLogoutModal = React.useCallback((data) => {
		setLogoutModalState(data);
	}, []);

	return (
		<AuthContext.Provider
			value={{
				logoutModal: logoutModalState,
				setLogoutModal: handleLogoutModal,
			}}
		>
			{children}
		</AuthContext.Provider>
	);
}

export default AuthContextProvider;

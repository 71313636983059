import React from "react";
import {useLocation, useNavigate, useParams} from "react-router";

import SnackbarComp from "../../Components/Snackbar";
import Page from "../../Components/Page";
import PageHeader from "../../Components/PageHeader";
import DeleteDialog from "../../Components/DeleteDialog";
import Table from "../../Components/Table";
import Filter from "../../Components/Filter";

import {getUserProfileApi, userGetAllQrCodesApi} from "../../Services/Users";
import BasicInfo from "./Components/BasicInfo";
import {Box, Typography} from "@mui/material";

const headerArray = [
	{
		title: "Name of QR Code",
		name: "title",
		width: 300,
		align: "left",
	},
	{
		title: "Category",
		objName: "qrType",
		name: "title",
		width: 300,
		align: "center",
	},
	{
		title: "QR Type",
		name: "type",
		width: 200,
		align: "center",
	},
	{
		title: "Deleted",
		name: "deleted",
		width: 100,
		align: "center",
	},
	{
		title: "Created On",
		name: "createdOn",
		width: 200,
		align: "center",
	},
	{
		title: "Scan Count",
		name: "scanCount",
		width: 150,
		align: "center",
	},
	{
		title: "Action",
		name: "action",
		actions: ["view"],
		width: 100,
		align: "center",
	},
];

export default function QrCodesList(props) {
	const navigate = useNavigate();
	const {systemUserId} = useParams();
	const location = useLocation();

	const [loadPage, setLoadPage] = React.useState("loading");
	const [dataItems, setDataItems] = React.useState();
	const [count, setCount] = React.useState(0);
	const [page, setPage] = React.useState(0);
	const [rowsPerPage, setRowsPerPage] = React.useState(10);
	const [deleteDialog, setDeleteDialog] = React.useState({open: false});
	const [snackbarState, setSnackbarState] = React.useState({
		open: false,
		message: "",
		severity: "success",
	});

	const getAllQrCodesApiCall = React.useCallback(() => {
		userGetAllQrCodesApi({
			payload: {
				userId: systemUserId,
				page: page,
				pageSize: rowsPerPage,
			},
		})
			.then((res) => {
				setCount(res.Count);
				setDataItems(res.Items);
				if (res.Items.length > 0) {
					setLoadPage("found");
				} else {
					setLoadPage("notFound");
				}
			})
			.catch((err) => {
				setLoadPage("error");
				console.error(err);
			});
	}, [systemUserId, page, rowsPerPage]);

	React.useEffect(() => {
		getAllQrCodesApiCall();
	}, [getAllQrCodesApiCall]);

	const handleChangePage = (event, pageNumber) => {
		if (pageNumber !== page) {
			setLoadPage("tableLoad");
		}
		setPage(pageNumber);
	};

	const handleChangeRowsPerPage = (event) => {
		if (rowsPerPage !== event.target.value) {
			setLoadPage("tableLoad");
		}
		setRowsPerPage(event.target.value);
	};

	const handleCreate = () => {
		navigate("/contents/create");
	};

	const handleView = (event) => {
		navigate("/users/qrcode/view/" + event, {
			state: {
				basicInfo: location.state
					? location.state.basicInfo
						? location.state.basicInfo
						: false
					: false,
			},
		});
	};

	return (
		<Page title="HotQR | User | QR Codes">
			<PageHeader
				title="QR Code of User"
				subTitle="View qr code of perticular user"
				goBack={true}
			/>
			{location.state && location.state.basicInfo && (
				<BasicInfo data={location.state.basicInfo} title="User Information" />
			)}
			<Table
				tableProps={{
					maxHeight: "79vh",
				}}
				stickyHeader={true}
				loadPage={loadPage}
				dataItems={dataItems}
				pagination={true}
				paginationState={{
					page: page,
					rowsPerPage: rowsPerPage,
					totalCount: count,
				}}
				headerArray={headerArray}
				displayTitle={"Qr Code"}
				handleChangePage={handleChangePage}
				handleChangeRowsPerPage={handleChangeRowsPerPage}
				handleView={handleView}
			/>
			<SnackbarComp
				snackbarState={snackbarState}
				handleClose={() => setSnackbarState((prev) => ({...prev, open: false}))}
			/>
		</Page>
	);
}

import React from "react";
import {
	Button,
	CircularProgress,
	Dialog,
	DialogActions,
	DialogContent,
	DialogContentText,
	DialogTitle,
	Typography,
} from "@mui/material";
import PropTypes from "prop-types";

import SnackbarComp from "./Snackbar";

function DeleteDialog(props) {
	const {
		state: {open = false, loading = false, notRecoverable = true},
		handleDelete = () =>
			SnackbarComp({
				props: {
					open: true,
					messsage:
						"Delete item function called but function not found! (Delete item Dialog Component)",
				},
			}),
		handleCancel = () =>
			SnackbarComp({
				props: {
					open: true,
					messsage:
						"Cancel delete item clicked but function not found! (Delete item Dialog Component)",
				},
			}),
	} = props;

	if (open && !loading) {
		return (
			<Dialog open={open}>
				<DialogTitle>{"Delete Item"}</DialogTitle>
				<DialogContent>
					<DialogContentText>
						<Typography sx={{display: "flex", flexDirection: "column", mt: 1}}>
							<span>Are you sure want to delete this item ?</span>
							<span style={{color: "#c72a2a"}}>
								{notRecoverable &&
									`(Please note after delete item it's not recoverable.)`}
							</span>
						</Typography>
					</DialogContentText>
				</DialogContent>
				<DialogActions>
					<Button
						onClick={handleCancel}
						variant="contained"
						style={{color: "#FFF", background: "#c72a2a"}}
					>
						No, Cancle
					</Button>
					<Button
						onClick={handleDelete}
						variant="contained"
						style={{color: "#FFF", background: "#1d8f48"}}
					>
						Yes, I'm Sure
					</Button>
				</DialogActions>
			</Dialog>
		);
	} else if (open && loading) {
		return (
			<Dialog open={open}>
				<DialogContent>
					<CircularProgress />
				</DialogContent>
			</Dialog>
		);
	} else return null;
}

export default DeleteDialog;

DeleteDialog.propTypes = {
	state: PropTypes.object.isRequired,
	handleDelete: PropTypes.func.isRequired,
	handleCancel: PropTypes.func.isRequired,
};

import React, {useEffect, useState} from "react";
import {useNavigate} from "react-router-dom";
import {Box, List, SwipeableDrawer} from "@mui/material";
import NavItem from "./NavItem";
import {topItems, bottomItems} from "./NavItemsList";

import HOTQr from "../../Assets/HOTQr.png";

function getTopItems() {
	let json = {
		list: topItems,
	};
	return json;
}

function getBottomItems() {
	let json = {
		list: bottomItems,
	};
	return json;
}

export default function Drawer(props) {
	const navigate = useNavigate();

	const topItems = getTopItems();

	const bottomItems = getBottomItems();

	const {staticContext, ...other} = props;

	const content = (
		<Box
			style={{
				display: "flex",
				flexDirection: "column",
				justifyContent: "space-between",
				height: "100vh",
				paddingBottom: "10px",
			}}
			sx={{
				bgcolor: "primary.main",
			}}
		>
			<Box>
				<Box
					onClick={() => navigate("/")}
					sx={{
						margin: "15px 0 0 0",
						height: "100px",
						width: "100%",
					}}
				>
					<img
						alt="logo"
						src={HOTQr}
						style={{
							filter: "grayscale(1) brightness(2)",
							height: "100%",
							width: "100%",
							objectFit: "contain",
						}}
					/>
				</Box>
				<Box sx={{p: 2}}>
					<List>
						{topItems.list.map((ele) => (
							<NavItem
								tag={ele.tag}
								link={ele.link}
								key={ele.id}
								title={ele.title}
								icon={ele.icon}
							/>
						))}
					</List>
				</Box>
			</Box>
			<Box
				sx={{
					p: 2,
					borderTop: "0.5px solid #FFF",
				}}
			>
				<List>
					{bottomItems.list.map((ele) => (
						<NavItem
							tag={ele.tag}
							link={ele.link}
							key={ele.id}
							title={ele.title}
							icon={ele.icon}
						/>
					))}
				</List>
			</Box>
		</Box>
	);

	return (
		<React.Fragment>
			<SwipeableDrawer
				open={true}
				anchor="left"
				onClose={() => {}}
				onOpen={() => {}}
				{...other}
			>
				{content}
			</SwipeableDrawer>
		</React.Fragment>
	);
}

import React from "react";
import {Box, Typography} from "@mui/material";
import moment from "moment";

import {getUserLicenseApi} from "../../../Services/Users";
import CircularProgressComp from "../../../Components/CircularProgress";
import Error from "../../../Components/Error";

const dataArray = [
	{
		title: "Current Active Plan",
		object: true,
		objectName: "planDetail",
		key: "title",
	},
	{
		title: "Current Active Plan Type",
		key: "subscriptionPlanType",
	},
	{
		title: "Plan Purchased On",
		key: "createdOn",
	},
	{
		title: "Plan Expiration",
		key: "expiredOn",
	},
];

const keyTypoStyle = {
	fontSize: "16px",
	letterSpacing: "0.7px",
	width: "280px",
	fontWeight: 600,
};

const valueTypoStyle = {
	fontSize: "16px",
	letterSpacing: "0.8px",
	fontWeight: 600,
};

function SubscriptionPlan({systemUserId}) {
	const [data, setData] = React.useState();

	const [loadPage, setLoadPage] = React.useState("load");

	const getQrUserProfileApiCall = React.useCallback(() => {
		setLoadPage("load");
		getUserLicenseApi(systemUserId)
			.then((res) => {
				setData(res);
				setLoadPage("found");
			})
			.catch((err) => {
				console.error(err);
				setLoadPage("error");
			});
	}, [systemUserId]);

	React.useEffect(() => {
		getQrUserProfileApiCall();
	}, [getQrUserProfileApiCall]);

	const renderComp = () => {
		switch (loadPage) {
			case "found":
				return (
					<Box
						sx={{
							display: "flex",
							width: "100%",
							mt: 1,
						}}
					>
						<Box>
							{dataArray?.map((ele, index) => (
								<Box
									key={index}
									sx={{
										display: "flex",
										mb: 0.1,
										borderBottom: "1px solid #D0D0D0",
										px: 2,
										py: 0.5,
									}}
								>
									<Typography sx={keyTypoStyle} color="primary">
										{ele.title + " "}
									</Typography>
									<Typography sx={valueTypoStyle}>
										{ele.key === "createdOn" ||
										ele.key === "expiredOn" ||
										ele.key === "purchasedOn"
											? data?.[ele.key]
												? moment(data?.[ele.key]).format("llll")
												: "<empty>"
											: ele.object
											? data?.[ele.objectName]?.[ele.key]
											: data?.[ele.key] === true
											? "Yes"
											: data?.[ele.key] === false
											? "No"
											: data?.[ele.key] === 0
											? "0"
											: data?.[ele.key] || "<empty>"}
									</Typography>
								</Box>
							))}
						</Box>
					</Box>
				);
			case "load":
				return <CircularProgressComp height="200px" />;
			case "error":
				return <Error height="250px" />;
			default:
				return null;
		}
	};

	return (
		<Box
			sx={{
				background: "#F0F0F0",
				py: 2.5,
				px: 4,
				borderRadius: "12px",
			}}
		>
			<Typography variant="h5">Subscription Plan Information</Typography>
			{renderComp()}
		</Box>
	);
}

export default SubscriptionPlan;

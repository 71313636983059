import React from "react";

import {Box, Typography} from "@mui/material";
import {FiFilter} from "react-icons/fi";

import Search from "./Components/Search";
import SnackbarComp from "./Components/Snackbar";
import FilterContext from "./Context";

function Filter(props) {
	const {
		search = false,
		searchProps,
		name = "",
		label = "",
		handleChange,
		items = [],
	} = props;

	const [snackbarState, setSnackbarState] = React.useState({open: false});

	return (
		<React.Fragment>
			<FilterContext.Provider
				value={{
					search: search ? search : false,
					searchProps: searchProps
						? searchProps
						: {name: "keyword", label: "Search Item", value: null},
					handleChange: handleChange
						? handleChange
						: () =>
								setSnackbarState({
									open: true,
									message:
										"Filter value change but handleChange function not found! (Filter)",
								}),
					items: items ? (items.lnegth > 0 ? items : []) : [],
				}}
			>
				<Box
					sx={{
						mb: 2,
						px: 2,
						pt: 1,
						display: "flex",
						alignItems: "center",
					}}
				>
					<Search />
					<SnackbarComp
						snackbarState={snackbarState}
						handleClose={() => setSnackbarState({open: false})}
					/>
				</Box>
			</FilterContext.Provider>
		</React.Fragment>
	);
}

export default Filter;

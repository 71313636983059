import React from "react";
import {
	Box,
	CircularProgress,
	Dialog,
	DialogContent,
	Typography,
} from "@mui/material";

function LoadModal({open, text, bgTrans = false}) {
	return (
		<Dialog
			open={open}
			sx={bgTrans ? {"& div": {background: "none", boxShadow: "none"}} : {}}
		>
			<DialogContent>
				<Box
					style={{
						height: "100px",
						width: "150px",
						display: "flex",
						flexDirection: "column",
						justifyContent: "center",
						alignItems: "center",
					}}
				>
					<CircularProgress />
					{text && (
						<Typography color="primary" sx={{mt: 2}}>
							{text}
						</Typography>
					)}
				</Box>
			</DialogContent>
		</Dialog>
	);
}

export default LoadModal;

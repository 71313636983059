import {CircularProgress} from "@mui/material";
import React from "react";

function CircularProgressComp({height = "80vh"}) {
	return (
		<div
			style={{
				display: "flex",
				justifyContent: "center",
				alignItems: "center",
				height: height,
				width: "100%",
			}}
		>
			<CircularProgress />
		</div>
	);
}

export default CircularProgressComp;

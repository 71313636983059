import React from "react";

const AuthContext = React.createContext({
	geoInfo: {},
	setGeoInfo: () => {},
	logoutModal: false,
	setLogoutModal: () => {},
});

export default AuthContext;

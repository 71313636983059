import React from "react";
import {Alert, Snackbar} from "@mui/material";

function SnackbarComp({snackbarState, handleClose}) {
	const {
		open = false,
		severity = "error",
		message = "Snackbar message is empty!",
	} = snackbarState;
	return (
		<Snackbar
			open={open}
			anchorOrigin={{vertical: "top", horizontal: "right"}}
			autoHideDuration={5000}
			onClose={handleClose}
		>
			<Alert onClose={handleClose} severity={severity} sx={{width: "100%"}}>
				{message}
			</Alert>
		</Snackbar>
	);
}

export default SnackbarComp;

import {CircularProgress} from "@mui/material";
import React from "react";

function CircularProgressComp() {
	return (
		<div
			style={{
				display: "flex",
				justifyContent: "center",
				alignItems: "center",
				height: "80vh",
				width: "100%",
			}}
		>
			<CircularProgress />
		</div>
	);
}

export default CircularProgressComp;

import React from "react";
// -------------- import mui components ------------- //
import {Button, IconButton} from "@mui/material";
// ------------------ import icons ------------------- //
import {BsTrashFill} from "react-icons/bs";
import {CgFormatSlash} from "react-icons/cg";
import {ImDownload2} from "react-icons/im";
import {MdEdit} from "react-icons/md";
import {Visibility, ErrorOutline} from "@mui/icons-material";
// -------------- import table context ------------- //
import TableContext from "../Context";

function ActionElements({action = "notFound", id = ""}) {
	const {handleViewEdit, handleEdit, handleView, handleDelete, handleDownload} =
		React.useContext(TableContext);

	switch (action) {
		case "view":
			return (
				<IconButton
					onClick={() => handleView(id)}
					sx={{
						border: "1px solid #888",
						borderRadius: "8px",
						ml: 2,
					}}
				>
					<Visibility />
				</IconButton>
			);
		case "edit":
			return (
				<IconButton
					onClick={() => handleEdit(id)}
					sx={{
						border: "1px solid #888",
						borderRadius: "8px",
						ml: 2,
					}}
				>
					<MdEdit />
				</IconButton>
			);
		case "view/edit":
			return (
				<IconButton
					onClick={() => handleViewEdit(id)}
					style={{
						border: "1px solid #888",
						borderRadius: "8px",
					}}
				>
					<Visibility /> <CgFormatSlash /> <MdEdit />
				</IconButton>
			);
		case "delete":
			return (
				<IconButton
					onClick={() => handleDelete(id)}
					sx={{
						border: "1px solid #888",
						borderRadius: "8px",
						ml: 2,
					}}
				>
					<BsTrashFill />
				</IconButton>
			);
		case "download":
			return (
				<IconButton
					onClick={() => handleDownload(id)}
					sx={{
						border: "1px solid #888",
						borderRadius: "8px",
						ml: 2,
					}}
				>
					<ImDownload2 />
				</IconButton>
			);
		case "notFound":
			return (
				<Button
					sx={{
						border: "1px solid #888",
						borderRadius: "8px",
						ml: 2,
					}}
					startIcon={<ErrorOutline />}
				>
					Table actions not found!
				</Button>
			);
		default:
			return null;
	}
}

export default ActionElements;

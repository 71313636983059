import React from "react";
import Lottie from "lottie-react";
import notFound from "../../Assets/Animation/notFound.json";

function NotFoundComp() {
	return (
		<div
			style={{
				display: "flex",
				justifyContent: "center",
				alignItems: "center",
				height: "60vh",
				width: "100%",
			}}
		>
			<Lottie animationData={notFound} style={{height: 300}} />
		</div>
	);
}

export default NotFoundComp;

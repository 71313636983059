import {services} from "./Environment.dev";

let token = "Basic aXRwbDppd2FudHVubGltaXRlZA==";

const PATH = "https://api.hotqrcode.com/";
const LOCALPATH = "http://localhost:4100/dev/";
// const AWS_LEAD_PATH =
// 	"https://ccav0ckq9b.execute-api.ap-southeast-1.amazonaws.com/dev/";

const urls = {
	headers: {
		"Content-Type": "application/json",
		Authorization: localStorage.getItem("token")
			? "Bearer " + localStorage.getItem("token")
			: token,
		"itpl-app-name": "Merchant-Registration-App",
		"itpl-app-version": "1.0-101",
		"itpl-app-type": "web",
		"itpl-client-id": "SS7052021-001",
		"itpl-app-os": "web",
		"Access-Control-Allow-Origin": "*",
	},
	PROFILE: {
		GET_PROFILE: services.USER__USER_API + "/profile/findById/",
		PROFILE_UPDATE: services.USER__USER_API + "/profile",
		GET_ALL_PROFILE: services.USER__USER_API + "/profile/filter",
	},
	SVOD_FIND: services.SVOD + "subscriptionpackage/find",
	LOGIN: services.AUTH,
	DASHBOARD: {
		GET_DASHBOARD: PATH + "qr/dashboard",
	},
	QRTYPE: {
		CREATE: PATH + "qr-types",
		FINDALL: PATH + "qr-types",
		FINDBYID: PATH + "qr-types/",
		UPDATE: PATH + "qr-types/",
		DELETE: PATH + "qr-types/",
		ACTIVE: PATH + "qr-types/active/",
		DEACTIVE: PATH + "qr-types/deactive/",
	},
	QRCONTENT: {
		FINDALL: PATH + "qr-content",
	},
	QR_USER: {
		GET_ALL_USER_PROFILE: services.USER__USER_API + "/profile/filter",
		GET_USER_PROFILE: services.USER__USER_API + "/profile/findById/",
		GET_USER_ALL_QRCODES: PATH + "qr/user/qrcodes",
		GET_USER_QRCODE: PATH + "qr/user/qrcode/",
		GET_USER_QR_INFO: PATH + "qr/user-profile/",
	},
	LICENSE: {
		CREATE: PATH + "qr-license",
		GETLICENSE: PATH + "qr-license/get-license",
		GET_USER_LICENSE: PATH + "qr-license/",
		PLAN: PATH + "qr-license/plans",
	},
	PLANS: {
		CREATE: PATH + "qr-plan",
		GET_ALL: PATH + "qr-plans",
		GET_BY_ID: PATH + "qr-plan/",
		UPDATE_BY_ID: PATH + "qr-plan/",
		COMPARISON_DATA: PATH + "qr-plan/comparison",
	},
};

export default urls;

import ThemeProvider from "./theme";
import Router from "./Router";
import ThemeColorPresets from "./Components/ThemeColorPresets";
import {BrowserRouter} from "react-router-dom";
import React from "react";
import AuthContextProvider from "./Context/AuthContextProvider";

function App() {
	return (
		<>
			<ThemeProvider>
				<ThemeColorPresets>
					<AuthContextProvider>
						<BrowserRouter>
							<Router />
						</BrowserRouter>
					</AuthContextProvider>
				</ThemeColorPresets>
			</ThemeProvider>
		</>
	);
}

export default App;

import urls from "./Urls";
import {executeGet} from "./Methods/iwant-rest-generic";
import {executePost} from "./Methods/iwant-rest-http-post";

export const createNewPlanApi = (requestLoad) => {
	let url = urls.PLANS.CREATE;
	let actionCode = "";
	let payload = requestLoad;
	let method = "POST";
	return executePost(url, actionCode, payload, method);
};

export const getAllPlansApi = () => {
	let url = urls.PLANS.GET_ALL;
	let actionCode = "";
	let payload = {};
	let method = "GET";
	return executeGet(url, actionCode, payload, method);
};

export const getPlanByIdApi = (requestId) => {
	let url = urls.PLANS.GET_BY_ID + requestId;
	let actionCode = "";
	let payload = {};
	let method = "GET";
	return executeGet(url, actionCode, payload, method);
};

export const updatePlanById = (requestLoad, requestId) => {
	let url = urls.PLANS.UPDATE_BY_ID + requestId;
	let actionCode = "";
	let payload = requestLoad;
	let method = "PUT";
	return executePost(url, actionCode, payload, method);
};

export const getComparisonDataApi = () => {
	let url = urls.PLANS.COMPARISON_DATA;
	let actionCode = "";
	let payload = {};
	let method = "GET";
	return executeGet(url, actionCode, payload, method);
};

export const updateComparisonDataApi = (requestLoad) => {
	let url = urls.PLANS.COMPARISON_DATA;
	let actionCode = "";
	let payload = requestLoad;
	let method = "PUT";
	return executePost(url, actionCode, payload, method);
};

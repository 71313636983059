import React from "react";
import {useLocation, useParams} from "react-router";
import moment from "moment";
import {Avatar, Box, Paper, Typography} from "@mui/material";

import Page from "../../Components/Page";
import CircularProgressComp from "../../Components/CircularProgress";
import Error from "../../Components/Error";

import {getUserQrCodeApi} from "../../Services/Users";

import PageHeader from "../../Components/PageHeader";
import BasicInfo from "./Components/BasicInfo";

const dataArray = [
	{
		title: "Qr Code Name",
		key: "title",
	},
	{
		title: "Type",
		key: "type",
	},
	{
		title: "Category",
		objName: "qrType",
		key: "title",
	},
	{
		title: "Password Protected",
		key: "passwordProtected",
	},
	{
		title: "Capture Leads",
		key: "captureLeads",
	},
	{
		title: "App Url",
		key: "appUrl",
	},
	{
		title: "Total Scan",
		key: "scanCount",
	},
];

const basicInfoArray = [
	{
		title: "Firstname",
		key: "firstName",
	},
	{
		title: "Lastname",
		key: "lastName",
	},
	{
		title: "Username",
		key: "username",
	},
	{
		title: "Mobile No.",
		key: "mobile",
	},
	{
		title: "Email",
		key: "email",
	},
	{
		title: "Created On",
		key: "createdOn",
	},
];

const keyTypoStyle = {
	fontSize: "16px",
	letterSpacing: "0.7px",
	width: "250px",
	fontWeight: 600,
};

const valueTypoStyle = {
	fontSize: "16px",
	letterSpacing: "0.8px",
	fontWeight: 600,
	width: "100%",
	wordBreak: "break-all",
};

function QrCodesView() {
	const {qrId} = useParams();
	const location = useLocation();

	const [data, setData] = React.useState();
	const [loadPage, setLoadPage] = React.useState("load");

	const getUserQrCodeApiCall = React.useCallback(() => {
		setLoadPage("load");
		getUserQrCodeApi(qrId)
			.then((res) => {
				setData(res);
				setLoadPage("found");
			})
			.catch((err) => {
				console.error(err);
				setLoadPage("error");
			});
	}, [qrId]);

	React.useEffect(() => {
		getUserQrCodeApiCall();
	}, [getUserQrCodeApiCall]);

	const renderComp = () => {
		switch (loadPage) {
			case "found":
				return (
					<React.Fragment>
						<Box
							sx={{
								display: "flex",
								alignItems: "center",
								width: "100%",
								mt: 1,
							}}
						>
							<Box
								sx={{
									mr: 4,
									border: "1px solid #999",
									borderRadius: "12px",
									height: "100%",
									p: 2,
								}}
							>
								<img
									alt=""
									src={data.qr}
									style={{
										height: "220px",
										objectFit: "contain",
									}}
								/>
							</Box>
							<Box>
								{dataArray?.map((ele, index) => (
									<Box
										key={index}
										sx={{
											display: "flex",
											mb: 0.1,
											borderBottom: "1px solid #D0D0D0",
											px: 2,
											py: 0.5,
										}}
									>
										<Typography sx={keyTypoStyle} color="primary">
											{ele.title + " "}
										</Typography>
										<Typography sx={valueTypoStyle}>
											{ele.key === "createdOn"
												? moment(data?.[ele.key]).format("llll")
												: data?.[ele.key] === true
												? "Yes"
												: data?.[ele.key] === false
												? "No"
												: data?.[ele.key] === 0
												? "0"
												: data?.[ele.key] === undefined
												? "No"
												: data?.[ele.key] || "<empty>"}
										</Typography>
									</Box>
								))}
							</Box>
						</Box>
					</React.Fragment>
				);
			case "load":
				return <CircularProgressComp height="200px" />;
			case "error":
				return <Error />;
			default:
				break;
		}
	};

	return (
		<Page title="HotQR | User | QRCode">
			<PageHeader
				title="View User QRCode"
				subTitle="View user qr code"
				goBack={true}
			/>
			{location.state && location.state.basicInfo && (
				<BasicInfo data={location.state.basicInfo} title="User Information" />
			)}
			<Box
				sx={{
					background: "#F0F0F0",
					py: 2.5,
					px: 4,
					mb: 2,
					borderRadius: "12px",
				}}
			>
				<Box
					sx={{
						display: "flex",
						justifyContent: "space-between",
						alignItems: "center",
					}}
				>
					<Typography variant="h5">QRCode Details</Typography>
				</Box>
				{renderComp()}
			</Box>
		</Page>
	);
}

export default QrCodesView;

import React from "react";
import {useParams} from "react-router";
import {Avatar, Paper, Tabs, Tab, Box, Typography} from "@mui/material";

import Page from "../../Components/Page";
import PageHeader from "../../Components/PageHeader";
import TabPanel from "../../Components/TabPanel";
import Error from "../../Components/Error";

import BasicInfo from "./Components/BasicInfo";
import QRCodeInfo from "./Components/QRCodeInfo";
import SubscriptionPlan from "./Components/SubscriptionPlan";

import {getUserProfileApi} from "../../Services/Users";
import CircularProgressComp from "../../Components/Table/Components/CircularProgress";

const tabStyle = {padding: "0 20px"};

function ViewUser(props) {
	const {userId} = useParams();

	const [tabState, setTabState] = React.useState(0);
	const [loadPage, setLoadPage] = React.useState("load");
	const [data, setData] = React.useState();

	const getUserProfileApiCall = React.useCallback(() => {
		getUserProfileApi(userId)
			.then((res) => {
				setLoadPage("found");
				setData(res);
			})
			.catch((err) => {
				console.error(err);
				setLoadPage("error");
			});
	}, [userId]);

	React.useEffect(() => {
		getUserProfileApiCall();
	}, [getUserProfileApiCall]);

	const renderComp = () => {
		return (
			<React.Fragment>
				<BasicInfo data={data} />
				{data && (
					<React.Fragment>
						<QRCodeInfo systemUserId={data?.systemUserId} basicInfo={data} />
						<SubscriptionPlan systemUserId={data?.systemUserId} />
					</React.Fragment>
				)}
			</React.Fragment>
		);
	};

	return (
		<Page title="HotQR | User | View">
			<PageHeader
				title="View User"
				subTitle="View all the information of system user"
				goBack={true}
			/>
			{renderComp()}
		</Page>
	);
}

export default ViewUser;

import React from "react";
import {useNavigate} from "react-router";
import {Avatar, Box, Button, Typography} from "@mui/material";
import moment from "moment";

import CircularProgressComp from "../../../Components/CircularProgress";
import Error from "../../../Components/Error";

import {getUserQrInfoApi} from "../../../Services/Users";

const dataArray = [
	{
		title: "Total Qr Codes",
		key: "totalQrCodes",
	},
	{
		title: "Active Qr Codes",
		key: "activeQrCodes",
	},
	{
		title: "Password Protected Qr Codes",
		key: "passwordProtectedQrCodes",
	},
	{
		title: "Leads Qr Codes",
		key: "leadsQrCodes",
	},
];

const keyTypoStyle = {
	fontSize: "16px",
	letterSpacing: "0.7px",
	width: "280px",
	fontWeight: 600,
};

const valueTypoStyle = {
	fontSize: "16px",
	letterSpacing: "0.8px",
	fontWeight: 600,
};

function QRCodeInfo({systemUserId, basicInfo}) {
	const navigate = useNavigate();

	const [data, setData] = React.useState();
	const [loadPage, setLoadPage] = React.useState("load");

	const countQrCodesFunc = React.useCallback((qrResult) => {
		const activeQr = qrResult?.Items?.map((ele) => {
			if (!ele.inactive) {
				return ele;
			} else return null;
		});
		const activeQrFilter = activeQr.filter((ele) => ele !== null);
		const activeQrCodes = activeQrFilter.length;
		const passProQr = qrResult.Items.map((ele) => {
			if (ele.passwordProtected) {
				return ele;
			} else return null;
		});
		const passProQrFilter = passProQr.filter((ele) => ele !== null);
		const passwordProtectedQrCodes = passProQrFilter.length;
		const leadsQr = qrResult.Items.map((ele) => {
			if (ele.captureLeads) {
				return ele;
			} else return null;
		});
		const leadsQrFilter = leadsQr.filter((ele) => ele !== null);
		const leadsQrCodes = leadsQrFilter.length;
		setData({
			...qrResult,
			totalQrCodes: qrResult.Count,
			activeQrCodes: activeQrCodes,
			passwordProtectedQrCodes: passwordProtectedQrCodes,
			leadsQrCodes: leadsQrCodes,
		});
	}, []);

	const getQrUserProfileApiCall = React.useCallback(() => {
		setLoadPage("load");
		getUserQrInfoApi(systemUserId)
			.then((res) => {
				setLoadPage("found");
				if (res.Items.length > 0) {
					countQrCodesFunc(res);
				} else {
					setData({
						...res,
						totalQrCodes: 0,
						activeQrCodes: 0,
						passwordProtectedQrCodes: 0,
						leadsQrCodes: 0,
					});
				}
			})
			.catch((err) => {
				setLoadPage("error");
				console.error(err);
			});
	}, [systemUserId, countQrCodesFunc]);

	React.useEffect(() => {
		getQrUserProfileApiCall();
	}, [getQrUserProfileApiCall]);

	const handleViewQrCodes = () => {
		navigate("/users/qrcode/list/" + systemUserId, {
			state: {basicInfo: basicInfo},
		});
	};

	const renderComp = () => {
		switch (loadPage) {
			case "found":
				return (
					<Box
						sx={{
							display: "flex",
							width: "100%",
							mt: 1,
						}}
					>
						<Box>
							{dataArray?.map((ele, index) => (
								<Box
									key={index}
									sx={{
										display: "flex",
										mb: 0.1,
										borderBottom: "1px solid #D0D0D0",
										px: 2,
										py: 0.5,
									}}
								>
									<Typography sx={keyTypoStyle} color="primary">
										{ele.title + " "}
									</Typography>
									<Typography sx={valueTypoStyle}>
										{ele.key === "createdOn"
											? moment(data?.[ele.key]).format("llll")
											: data?.[ele.key] === true
											? "Yes"
											: data?.[ele.key] === false
											? "No"
											: data?.[ele.key] === 0
											? "0"
											: data?.[ele.key] || "<empty>"}
									</Typography>
								</Box>
							))}
						</Box>
					</Box>
				);
			case "load":
				return <CircularProgressComp height="200px" />;
			case "error":
				return <Error height="250px" />;
			default:
				return null;
		}
	};
	return (
		<Box
			sx={{
				background: "#F0F0F0",
				py: 2.5,
				px: 4,
				mb: 2,
				borderRadius: "12px",
			}}
		>
			<Box
				sx={{
					display: "flex",
					justifyContent: "space-between",
					alignItems: "center",
				}}
			>
				<Typography variant="h5">QRCode Information</Typography>
				<Button size="small" variant="contained" onClick={handleViewQrCodes}>
					View Qr Codes
				</Button>
			</Box>
			{renderComp()}
		</Box>
	);
}

export default QRCodeInfo;

import React from "react";
import {Avatar, Box, Typography} from "@mui/material";
import moment from "moment";

import CircularProgressComp from "../../../Components/CircularProgress";

const dataArray = [
	{
		title: "Firstname",
		key: "firstName",
	},
	{
		title: "Lastname",
		key: "lastName",
	},
	{
		title: "Username",
		key: "username",
	},
	{
		title: "Mobile No.",
		key: "mobile",
	},
	{
		title: "Email",
		key: "email",
	},
	{
		title: "Created On",
		key: "createdOn",
	},
];

const keyTypoStyle = {
	fontSize: "16px",
	letterSpacing: "0.7px",
	width: "150px",
	fontWeight: 600,
};

const valueTypoStyle = {
	fontSize: "16px",
	letterSpacing: "0.8px",
	fontWeight: 600,
};

function BasicInfo({data, title}) {
	return (
		<Box
			sx={{
				background: "#F0F0F0",
				py: 2.5,
				px: 4,
				mb: 2,
				borderRadius: "12px",
			}}
		>
			<Typography variant="h5">
				{title ? title : "Basic Information"}
			</Typography>
			{data ? (
				<Box
					sx={{
						display: "flex",
						width: "100%",
						alignItems: "center",
						mt: 1,
					}}
				>
					<Avatar
						sx={{height: "120px", width: "120px", fontSize: "50px", mr: 5}}
					>
						{data?.firstName?.slice(0, 1)}
						{data?.lastName?.slice(0, 1)}
					</Avatar>
					<Box>
						{dataArray?.map((ele, index) => (
							<Box
								key={index}
								sx={{
									display: "flex",
									mb: 0.1,
									borderBottom: "1px solid #D0D0D0",
									px: 2,
									py: 0.5,
								}}
							>
								<Typography sx={keyTypoStyle} color="primary">
									{ele.title + " "}
								</Typography>
								<Typography sx={valueTypoStyle}>
									{ele.key === "createdOn"
										? data?.[ele.key]
											? moment(data?.[ele.key]).format("llll")
											: "<empty>"
										: data?.[ele.key] === true
										? "Yes"
										: data?.[ele.key] === false
										? "No"
										: data?.[ele.key] === 0
										? "0"
										: data?.[ele.key] || "<empty>"}
								</Typography>
							</Box>
						))}
					</Box>
				</Box>
			) : (
				<CircularProgressComp height="200px" />
			)}
		</Box>
	);
}

export default BasicInfo;

import React from "react";
import {Box, Button, Typography} from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import {ArrowBack} from "@mui/icons-material";
import {useNavigate} from "react-router";

function PageHeader({
	title,
	subTitle,
	displayTitle,
	createBtn,
	goBack,
	handleCreate,
}) {
	const navigate = useNavigate();
	const handleGoBack = () => {
		navigate(-1);
	};

	return (
		<Box
			sx={{
				display: "flex",
				justifyContent: "space-between",
				alignItems: "center",
				borderBottom: "1px solid #999",
				mb: 2,
				px: 2,
				py: 1.5,
			}}
		>
			<Box>
				<Typography variant="h4" sx={{color: "#444"}}>
					{title}
				</Typography>
				{subTitle && (
					<Typography variant="body1" sx={{color: "#444", mt: -0.5, ml: 0.3}}>
						{subTitle}
					</Typography>
				)}
			</Box>
			{createBtn && (
				<Button
					onClick={handleCreate}
					startIcon={<AddIcon />}
					variant="contained"
				>
					Create {displayTitle}
				</Button>
			)}
			{goBack && (
				<Button
					onClick={handleGoBack}
					startIcon={<ArrowBack />}
					variant="contained"
				>
					Back
				</Button>
			)}
		</Box>
	);
}

export default PageHeader;

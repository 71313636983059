import {Box, Grid, Typography} from "@mui/material";
import React from "react";
import SwitchComp from "./Switch";
import TitledTextField from "./TitledTextField";

const fieldCostArray = [
	{
		name: "monthly_cost",
		title: "Enter Monthly Cost of Plan",
		type: "text",
		priceAdornment: true,
	},
	{
		name: "annual_cost",
		title: "Enter Annual Cost of Plan (Monthly Cost/Per Month Cost)",
		type: "text",
		priceAdornment: true,
	},
];

function FieldsComp({state, setState}) {
	const {
		title,
		lifetime_cost_switch,
		lifetime_cost,
		qrcode_limit,
		qrcode_scan_limit_switch,
		qrcode_scan_limit,
	} = state;

	const handleChangeDetails = (e) => {
		const {name, value} = e.target;
		setState((prev) => ({
			...prev,
			[name]: value,
		}));
	};

	return (
		<Box
			sx={{
				p: 3,
				mx: 2,
				background: "#FFF",
				border: "1px solid #999",
				borderRadius: "12px",
			}}
		>
			<Typography sx={{mb: 2}} color="primary" variant="h5">
				Plan Details{" "}
				<span style={{fontSize: "15px"}}>(All the fields are important)</span>
			</Typography>
			<Grid container spacing={3}>
				<Grid item xs={12}>
					<TitledTextField
						data={{
							name: "title",
							title: "Enter plan name",
							type: "text",
						}}
						value={title}
						onChange={handleChangeDetails}
					/>
				</Grid>
				<Grid item xs={12}>
					<Grid container spacing={2}>
						<Grid item lg={12} xl={4}>
							<SwitchComp
								title="This Plan Available for Lifetime Single Cost ?"
								onChange={(e) =>
									setState((prev) => ({
										...prev,
										lifetime_cost_switch: e.target.checked,
									}))
								}
								checked={lifetime_cost_switch}
							/>
						</Grid>
						{lifetime_cost_switch ? (
							<Grid item lg={6} xl={4}>
								<TitledTextField
									data={{
										name: "lifetime_cost",
										title: "Enter Lifetime Cost of Plan",
										type: "text",
										priceAdornment: true,
									}}
									value={lifetime_cost}
									onChange={handleChangeDetails}
								/>
							</Grid>
						) : (
							fieldCostArray.map((ele, index) => (
								<Grid item lg={6} xl={4} key={index}>
									<TitledTextField
										data={ele}
										value={state[ele.name]}
										onChange={handleChangeDetails}
									/>
								</Grid>
							))
						)}
					</Grid>
				</Grid>
				<Grid item xs={6}>
					<TitledTextField
						data={{
							name: "qrcode_limit",
							title: "Enter QR Code Creation Limit",
							type: "number",
						}}
						value={qrcode_limit}
						onChange={handleChangeDetails}
					/>
				</Grid>
				<Grid item xs={12}>
					<Grid container spacing={2}>
						<Grid item lg={6} xl={4}>
							<SwitchComp
								title="This Plan Have Any Limitaion For Scan Qr Code ?"
								onChange={(e) =>
									setState((prev) => ({
										...prev,
										qrcode_scan_limit_switch: e.target.checked,
										qrcode_scan_limit: "",
									}))
								}
								checked={qrcode_scan_limit_switch}
							/>
						</Grid>
						{qrcode_scan_limit_switch && (
							<Grid item lg={6} xl={4}>
								<TitledTextField
									data={{
										name: "qrcode_scan_limit",
										title: "Enter Limit of Scan QR Code",
										type: "text",
									}}
									value={qrcode_scan_limit}
									onChange={handleChangeDetails}
								/>
							</Grid>
						)}
					</Grid>
				</Grid>
			</Grid>
		</Box>
	);
}

export default FieldsComp;

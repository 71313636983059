import React from "react";
import {
	Box,
	Container,
	Typography,
	Paper,
	Table,
	TableBody,
	TableCell,
	TableContainer,
	TableHead,
	TableRow,
	TableFooter,
	useTheme,
	CircularProgress,
} from "@mui/material";
import {makeStyles} from "@mui/styles";
import Moment from "moment";

import TablePaginationComp from "./TablePagination";

import CircularProgressComp from "./CircularProgress";

import ActionElements from "./ActionElements";
import Error from "./DisplayMessage/Error";
import NoData from "./DisplayMessage/NoData";
import NotFound from "./DisplayMessage/NotFound";
import EmptyData from "./DisplayMessage/EmptyData";
import TableContext from "../Context";

const useStyles = makeStyles((theme) => ({
	graylight: {
		color: "#787878",
	},
}));

export default function TableComp() {
	const classes = useStyles();
	const theme = useTheme();
	const tableContext = React.useContext(TableContext);
	const {
		tableProps,
		srNumber,
		stickyHeader,
		dataItems,
		loadPage,
		headerArray,
		pagination,
		headerProps,
	} = tableContext;

	const [loadPageState, setLoadPageState] = React.useState();

	React.useEffect(() => {
		if (loadPage === "found") {
			if (!dataItems && !headerArray) {
				setLoadPageState("emptyboth");
			} else if (dataItems && !headerArray) {
				setLoadPageState("emptytableHeaderArray");
			} else if (!dataItems && headerArray) {
				if (loadPage === "tableLoad") {
					setLoadPageState("emptydataItems");
				} else {
					setLoadPageState(loadPage);
				}
			} else if (dataItems?.length > 0 && headerArray?.length > 0) {
				setLoadPageState("found");
			} else {
				setLoadPageState(loadPage);
			}
		} else {
			setLoadPageState(loadPage);
		}
	}, [dataItems, headerArray, loadPage]);

	const renderComp = () => {
		switch (loadPageState) {
			case "found":
				return (
					<Paper
						style={{
							padding: "0px 0",
							boxShadow:
								"rgba(0, 0, 0, 0.12) 0px 1px 3px, rgba(0, 0, 0, 0.24) 0px 1px 2px",
							borderRadius: "8px",
							background: "#FFF",
							overflow: stickyHeader ? "hidden" : "",
							width: "100%",
						}}
					>
						<TableContainer
							style={{
								width: "100% !important",
								paddingLeft: "0px !important",
								paddingRight: "0px !important",
								maxHeight: stickyHeader
									? tableProps?.maxHeight
										? tableProps?.maxHeight
										: "650px"
									: "100%",
							}}
						>
							<Table stickyHeader={stickyHeader}>
								<TableHead>
									<TableRow>
										{srNumber && (
											<TableCell
												sx={{
													boxShadow: "none !important",
													borderRadius: stickyHeader
														? ""
														: "8px 0 0 0 !important",
													padding: "8px",
													paddingLeft: "8px !important",
												}}
												width="100"
												style={{
													background:
														headerProps?.bgColor || theme.palette.primary.main,
													textAlign: "center",
												}}
											>
												<Typography
													variant="body2"
													sx={{
														color:
															headerProps?.textColor ||
															theme.palette.common.white,
													}}
												>
													<b>Sr. No.</b>
												</Typography>
											</TableCell>
										)}
										{(headerArray &&
											headerArray?.map((ele, index) => {
												return (
													<TableCell
														key={index}
														sx={{
															boxShadow: "none !important",
															borderRadius: stickyHeader
																? ""
																: srNumber
																? ""
																: index === 1
																? "8px 0 0 0 !important"
																: index + 1 === headerArray?.length || 0
																? "0 8px 0 0 !important"
																: "0px !important",
														}}
														width={ele.width}
														style={{
															background:
																headerProps?.bgColor ||
																theme.palette.primary.main,
															textAlign: ele.align,
														}}
													>
														<Typography
															variant="h6"
															sx={{
																color:
																	headerProps?.textColor ||
																	theme.palette.common.white,
															}}
														>
															<b>{ele.title}</b>
														</Typography>
													</TableCell>
												);
											})) ||
											null}
									</TableRow>
								</TableHead>
								<TableBody>
									{(dataItems &&
										dataItems?.map((ele, index) => {
											// if (index > 10) {
											return (
												<React.Fragment key={index}>
													<TableRow
														style={{
															background:
																index % 2 === 0 ? "#fafafa" : "#f0f0f0",
														}}
													>
														{srNumber && (
															<TableCell
																width="100"
																sx={{
																	p: 2,
																	paddingLeft: "8px !important",
																	textAlign: "center",
																}}
															>
																<Typography
																	variant="body1"
																	className={classes.graylight}
																>
																	{index + 1}
																</Typography>
															</TableCell>
														)}
														{(headerArray &&
															headerArray?.map((item, iIndex) => {
																if (item.name === "action") {
																	return (
																		<TableCell
																			key={iIndex}
																			width={item.width}
																			style={{
																				padding: "8px",
																				textAlign: "center",
																			}}
																		>
																			<Box
																				sx={{
																					display: "flex",
																					justifyContent: "flex-end",
																					pr: 2,
																				}}
																			>
																				{(item &&
																					item?.actions &&
																					item?.actions?.map(
																						(aItem, aIndex) => (
																							<React.Fragment key={aIndex}>
																								<ActionElements
																									action={aItem}
																									id={ele?.id || ele?._id || ""}
																								/>
																							</React.Fragment>
																						)
																					)) ||
																					null}
																			</Box>
																		</TableCell>
																	);
																} else {
																	return (
																		<TableCell
																			key={iIndex}
																			width={item.width}
																			sx={{px: 1, py: 2, textAlign: item.align}}
																		>
																			<Typography
																				variant="body1"
																				className={classes.graylight}
																				sx={{px: 1}}
																			>
																				{item.name === "createdOn" ||
																				item.name === "submittedOn" ? (
																					<b>
																						{Moment(ele.createdOn).format(
																							tableProps.dateFormat
																								? tableProps.dateFormat
																								: "l LT"
																						)}
																					</b>
																				) : item.objName ? (
																					<b>
																						{ele?.[item.objName]?.[item.name] ||
																							"Empty"}
																					</b>
																				) : (
																					<b>
																						{ele?.[item.name] === true
																							? "Yes"
																							: ele?.[item.name] === false
																							? "No"
																							: ele?.[item.name] === 0
																							? "0"
																							: ele?.[item.name] || "<Empty>"}
																					</b>
																				)}
																			</Typography>
																		</TableCell>
																	);
																}
															})) ||
															null}
													</TableRow>
												</React.Fragment>
											);
											// } else return null;
										})) ||
										null}
								</TableBody>
								<TableFooter>
									{pagination && <TablePaginationComp />}
								</TableFooter>
							</Table>
						</TableContainer>
					</Paper>
				);
			case "tableLoad":
				return (
					<Paper
						style={{
							padding: "0px 0",
							boxShadow:
								"rgba(0, 0, 0, 0.12) 0px 1px 3px, rgba(0, 0, 0, 0.24) 0px 1px 2px",
							borderRadius: "8px",
							background: "#FFF",
							overflow: stickyHeader ? "hidden" : "",
							width: "100%",
						}}
					>
						<TableContainer
							style={{
								width: "100% !important",
								paddingLeft: "0px !important",
								paddingRight: "0px !important",
								maxHeight: stickyHeader
									? tableProps?.maxHeight
										? tableProps?.maxHeight
										: "650px"
									: "100%",
							}}
						>
							<Table stickyHeader={stickyHeader}>
								<TableHead>
									<TableRow>
										{srNumber && (
											<TableCell
												sx={{
													boxShadow: "none !important",
													borderRadius: stickyHeader
														? ""
														: "8px 0 0 0 !important",
													padding: "8px",
													paddingLeft: "8px !important",
												}}
												width="100"
												style={{
													background:
														headerProps?.bgColor || theme.palette.primary.main,
													textAlign: "center",
												}}
											>
												<Typography
													variant="body2"
													sx={{
														color:
															headerProps?.textColor ||
															theme.palette.common.white,
													}}
												>
													<b>Sr. No.</b>
												</Typography>
											</TableCell>
										)}
										{(headerArray &&
											headerArray?.map((ele, index) => {
												return (
													<TableCell
														key={index}
														sx={{
															boxShadow: "none !important",
															borderRadius: stickyHeader
																? ""
																: srNumber
																? ""
																: index === 1
																? "8px 0 0 0 !important"
																: index + 1 === headerArray?.length || 0
																? "0 8px 0 0 !important"
																: "0px !important",
														}}
														width={ele.width}
														style={{
															background:
																headerProps?.bgColor ||
																theme.palette.primary.main,
															textAlign: ele.align,
														}}
													>
														<Typography
															variant="h6"
															sx={{
																color:
																	headerProps?.textColor ||
																	theme.palette.common.white,
															}}
														>
															<b>{ele.title}</b>
														</Typography>
													</TableCell>
												);
											})) ||
											null}
									</TableRow>
								</TableHead>
								<TableBody>
									<TableRow>
										{headerArray?.map((ele, index) => {
											console.log(Math.round(headerArray?.length / 2));
											if (index + 1 === Math.round(headerArray?.length / 2)) {
												return (
													<TableCell>
														<Box
															sx={{
																height: "40vh",
																display: "flex",
																alignItems: "center",
															}}
														>
															<CircularProgress />
														</Box>
													</TableCell>
												);
											} else {
												return <TableCell />;
											}
										})}
									</TableRow>
								</TableBody>
								<TableFooter>
									{pagination && <TablePaginationComp />}
								</TableFooter>
							</Table>
						</TableContainer>
					</Paper>
				);
			case "noData":
				return <NoData />;
			case "notFound":
				return <NotFound />;
			case "error":
				return <Error />;
			case "loading":
				return <CircularProgressComp />;
			case "emptyboth":
			case "emptytableHeaderArray":
			case "emptydataItems":
				return <EmptyData loadPageState={loadPageState} />;
			default:
				return <CircularProgressComp />;
		}
	};

	return <React.Fragment>{renderComp()}</React.Fragment>;
}

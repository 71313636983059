import React from "react";
import {useNavigate} from "react-router";
import {Container} from "@mui/material";

import {getUsersApi} from "../../Services/Users";

import SnackbarComp from "../../Components/Snackbar";
import Page from "../../Components/Page";
import PageHeader from "../../Components/PageHeader";
import Table from "../../Components/Table";
import DeleteDialog from "../../Components/DeleteDialog";
import Filter from "../../Components/Filter";

const headerArray = [
	{
		title: "Name",
		name: "name",
		width: 220,
		align: "left",
	},
	{
		title: "Username",
		name: "username",
		width: 180,
		align: "left",
	},
	{
		title: "Mobile No.",
		name: "mobile",
		width: 150,
		align: "left",
	},
	{
		title: "Email",
		name: "email",
		width: 300,
		align: "left",
	},
	{
		title: "Gender",
		name: "gender",
		width: 100,
		align: "center",
	},
	{
		title: "Created On",
		name: "createdOn",
		width: 250,
		align: "center",
	},
	{
		title: "Action",
		name: "action",
		actions: ["view"],
		width: 100,
		align: "right",
	},
];

export default function Categories(props) {
	const navigate = useNavigate();

	const [loadPage, setLoadPage] = React.useState("loading");
	const [dataItems, setDataItems] = React.useState();
	const [deleteDialog, setDeleteDialog] = React.useState({open: false});
	const [count, setCount] = React.useState(0);
	const [page, setPage] = React.useState(0);
	const [rowsPerPage, setRowsPerPage] = React.useState(10);
	const [snackbarState, setSnackbarState] = React.useState({
		open: false,
		message: "",
		severity: "success",
	});

	const getItemsApiCall = React.useCallback(() => {
		getUsersApi({
			domain: "444-555-666",
			page: page,
			pageSize: rowsPerPage,
		})
			.then((res) => {
				setCount(res.totalElements);
				if (res.content.length > 0) {
					const newItems = res.content?.map((ele) => {
						if (ele.firstName && ele.lastName) {
							return {
								...ele,
								name: `${ele?.firstName || ""} ${ele?.lastName || ""}`,
							};
						} else return ele;
					});
					setDataItems(newItems);
					setLoadPage("found");
				} else {
					setDataItems([]);
					setLoadPage("notFound");
				}
			})
			.catch((err) => {
				setLoadPage("error");
				console.error(err);
			});
	}, [page, rowsPerPage]);

	React.useEffect(() => {
		getItemsApiCall();
	}, [getItemsApiCall]);

	const handleChangePage = (event, pageNumber) => {
		if (pageNumber !== page) {
			setLoadPage("tableLoad");
		}
		setPage(pageNumber);
	};

	const handleChangeRowsPerPage = (event) => {
		if (rowsPerPage !== event.target.value) {
			setLoadPage("tableLoad");
		}
		setRowsPerPage(event.target.value);
	};

	const handleView = (id) => {
		navigate("/users/view/" + id);
	};

	const handleDelete = () => {
		setDeleteDialog({open: true, loading: true});
		alert("Delete QR Content");
	};

	const handleCancel = () => {
		setDeleteDialog({open: false});
	};

	const handleChangeFilter = (filter, name, value) => {};

	return (
		<Page title="HotQR | Users">
			<PageHeader
				title="Users"
				subTitle="View users profile"
				createBtn={false}
			/>
			<Filter
				search={true}
				searchProps={{name: "keywords", label: "Search items"}}
				handleChange={handleChangeFilter}
			/>
			<Table
				tableProps={{
					maxHeight: "79vh",
				}}
				stickyHeader={true}
				loadPage={loadPage}
				dataItems={dataItems}
				pagination={true}
				paginationState={{
					page: page,
					rowsPerPage: rowsPerPage,
					totalCount: count,
				}}
				headerArray={headerArray}
				displayTitle={"User"}
				handleView={handleView}
				handleChangePage={handleChangePage}
				handleChangeRowsPerPage={handleChangeRowsPerPage}
			/>
			<DeleteDialog
				state={deleteDialog}
				handleDelete={handleDelete}
				handleCancel={handleCancel}
			/>
			<SnackbarComp
				snackbarState={snackbarState}
				handleClose={() => setSnackbarState((prev) => ({...prev, open: false}))}
			/>
		</Page>
	);
}

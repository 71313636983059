import urls from "./Urls";
import {executePost} from "./Methods/PUT_OR_POST";
import {executeGet} from "./Methods/GET_OR_DELETE";
import {executeGet as executeGetNode} from "./Methods/iwant-rest-generic";
import {executePost as executePostNode} from "./Methods/iwant-rest-http-post";

export const getUsersApi = (requestLoad) => {
	let url = urls.QR_USER.GET_ALL_USER_PROFILE;
	let actionCode = "ACTION_FILTER_PROFILE";
	let payload = requestLoad;
	let method = "POST";
	return executePost(url, actionCode, payload, method);
};

export const getUserProfileApi = (requestId) => {
	let url = urls.QR_USER.GET_USER_PROFILE + requestId;
	let actionCode = "";
	let payload = {};
	let method = "GET";
	return executeGet(url, actionCode, payload, method);
};

export const getUserQrInfoApi = (requestId) => {
	let url = urls.QR_USER.GET_USER_QR_INFO + requestId;
	let actionCode = "";
	let payload = {};
	let method = "GET";
	return executeGetNode(url, actionCode, payload, method);
};
export const getUserLicenseApi = (requestId) => {
	let url = urls.LICENSE.GET_USER_LICENSE + requestId;
	let actionCode = "";
	let payload = {};
	let method = "GET";
	return executeGetNode(url, actionCode, payload, method);
};

export const userGetAllQrCodesApi = (requestLoad) => {
	let url = urls.QR_USER.GET_USER_ALL_QRCODES;
	let actionCode = "";
	let payload = requestLoad;
	let method = "POST";
	return executePostNode(url, actionCode, payload, method);
};

export const getUserQrCodeApi = (requestId) => {
	let url = urls.QR_USER.GET_USER_QRCODE + requestId;
	let actionCode = "";
	let payload = {};
	let method = "GET";
	return executeGetNode(url, actionCode, payload, method);
};

import React from "react";
import {Box, Card} from "@mui/material";

function EmptyData({loadPageState}) {
	switch (loadPageState) {
		case "emptyboth":
			return (
				<Card sx={{width: "100%", px: 5, py: 2, background: "#f0f0f0"}}>
					<Box>
						<ol>
							<li>
								Please provide "headerArray" array prop to table componnet
							</li>
							<li>Please provide "dataItems" array prop to table componnet</li>
						</ol>
					</Box>
				</Card>
			);
		case "emptytableHeaderArray":
			return (
				<Card sx={{width: "100%", p: 3, background: "#f0f0f0"}}>
					<Box>
						<ol>
							<li>
								Please provide "headerArray" array prop to table componnet
							</li>
						</ol>
					</Box>
				</Card>
			);
		case "emptydataItems":
			return (
				<Card sx={{width: "100%", p: 3, background: "#f0f0f0"}}>
					<Box>
						<ol>
							<li>Please provide "dataItems" array prop to table componnet</li>
						</ol>
					</Box>
				</Card>
			);
		default:
			return null;
	}
}

export default EmptyData;

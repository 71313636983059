import React from "react";
import {Button, Typography} from "@mui/material";
import Lottie from "lottie-react";
import empty from "../../Assets/Animation/empty.json";
import TableContext from "../../Context";

function Empty() {
	const {handleCreate, displayTitle} = React.useContext(TableContext);
	return (
		<div
			style={{
				display: "flex",
				justifyContent: "center",
				alignItems: "center",
				height: "80vh",
				width: "100%",
				flexDirection: "column",
			}}
		>
			<Lottie animationData={empty} style={{height: 300, color: "red"}} />
			<Typography style={{fontSize: "22px"}} color="primary">
				<b>
					Looks like you haven’t created any {displayTitle.toLowerCase()} yet.
					Create your first {displayTitle.toLowerCase()} now.
				</b>
			</Typography>
			<Button
				color="primary"
				variant="contained"
				onClick={handleCreate}
				style={{marginTop: "20px"}}
			>
				Create New {displayTitle}
			</Button>
		</div>
	);
}

export default Empty;

import React from "react";
import {Button, InputAdornment, TextField} from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import FilterContext from "../Context";

function Search(props) {
	const {searchProps, handleChange} = React.useContext(FilterContext);
	const {name, label, value} = searchProps;

	return (
		<React.Fragment>
			<TextField
				label={label}
				placeholder={label + "..."}
				type="text"
				value={value}
				name={name}
				onChange={(e) => handleChange("search", e.target.name, e.target.value)}
				size="small"
				InputProps={{
					startAdornment: (
						<InputAdornment position="start">
							<SearchIcon />
						</InputAdornment>
					),
					endAdornment: (
						<InputAdornment position="end">
							<Button>Search</Button>
						</InputAdornment>
					),
				}}
				sx={{minWidth: {sm: "100%", md: "50%", lg: "250px", xl: "400px"}}}
			/>
		</React.Fragment>
	);
}

export default Search;

import {Box, TextField, Typography, InputAdornment} from "@mui/material";
import React from "react";

function TitledTextField({data, onChange, fontSize, color, ...other}) {
	const {name, title, type, priceAdornment} = data;
	return (
		<Box sx={{display: "flex", flexDirection: "column", width: "100%"}}>
			<Typography
				sx={{
					mb: 0.2,
					fontSize: fontSize ? fontSize : "16px",
					fontWeight: 600,
					color: color === "primary" ? "primary.main" : "#666",
				}}
			>
				{title}
			</Typography>
			<TextField
				name={name}
				type={type ? type : "text"}
				onChange={onChange}
				size="small"
				fullWidth
				color="primary"
				placeholder={"Write here..."}
				required
				{...other}
				InputProps={
					priceAdornment
						? {
								endAdornment: (
									<InputAdornment position="end">KSh</InputAdornment>
								),
						  }
						: {}
				}
			/>
		</Box>
	);
}

export default TitledTextField;

import React from "react";
import PropTypes from "prop-types";
import {useTheme} from "@mui/material/styles";
import {
	Box,
	TablePagination,
	TableRow,
	IconButton,
	TableCell,
} from "@mui/material";
import {
	KeyboardArrowRight,
	KeyboardArrowLeft,
	FirstPage,
	LastPage,
} from "@mui/icons-material";
import TableContext from "../Context";

function TablePaginationComp() {
	const {
		paginationState: {totalCount, page, rowsPerPage},
		handleChangePage,
		handleChangeRowsPerPage,
	} = React.useContext(TableContext);

	const TablePaginationActions = (props) => {
		const theme = useTheme();
		const {count, page, rowsPerPage, onPageChange} = props;

		const handleFirstPageButtonClick = (event) => {
			onPageChange(event, 0);
		};

		const handleBackButtonClick = (event) => {
			onPageChange(event, page - 1);
		};

		const handleNextButtonClick = (event) => {
			onPageChange(event, page + 1);
		};

		const handleLastPageButtonClick = (event) => {
			onPageChange(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
		};

		return (
			<Box sx={{flexShrink: 0, ml: 2.5}}>
				<IconButton
					onClick={handleFirstPageButtonClick}
					disabled={page === 0}
					aria-label="first page"
				>
					{theme.direction === "rtl" ? <LastPage /> : <FirstPage />}
				</IconButton>
				<IconButton
					onClick={handleBackButtonClick}
					disabled={page === 0}
					aria-label="previous page"
				>
					{theme.direction === "rtl" ? (
						<KeyboardArrowRight />
					) : (
						<KeyboardArrowLeft />
					)}
				</IconButton>
				<IconButton
					onClick={handleNextButtonClick}
					disabled={page >= Math.ceil(count / rowsPerPage) - 1}
					aria-label="next page"
				>
					{theme.direction === "rtl" ? (
						<KeyboardArrowLeft />
					) : (
						<KeyboardArrowRight />
					)}
				</IconButton>
				<IconButton
					onClick={handleLastPageButtonClick}
					disabled={page >= Math.ceil(count / rowsPerPage) - 1}
					aria-label="last page"
				>
					{theme.direction === "rtl" ? <FirstPage /> : <LastPage />}
				</IconButton>
			</Box>
		);
	};

	TablePaginationActions.propTypes = {
		count: PropTypes.number.isRequired,
		onPageChange: PropTypes.func.isRequired,
		page: PropTypes.number.isRequired,
		rowsPerPage: PropTypes.number.isRequired,
	};

	return (
		<TableRow>
			<TablePagination
				style={{paddingRight: "20px"}}
				rowsPerPageOptions={[5, 10, 30]}
				count={totalCount}
				rowsPerPage={rowsPerPage}
				page={page}
				onPageChange={handleChangePage}
				onRowsPerPageChange={handleChangeRowsPerPage}
				ActionsComponent={TablePaginationActions}
			/>
		</TableRow>
	);
}
export default TablePaginationComp;

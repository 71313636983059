import React from "react";

const defaultContext = {
	search: false,
	name: "",
	label: "",
	handleChange: () => {},
	items: [],
};

const FilterContext = React.createContext(defaultContext);

export default FilterContext;

import React from "react";
import {useNavigate} from "react-router";
import {Box, Button} from "@mui/material";

import Page from "../../Components/Page";
import PageHeader from "../../Components/PageHeader";
import Features from "./Components/Features";
import FieldsComp from "./Components/FieldsComp";
import {createNewPlanApi} from "../../Services/Plans";

function CreatePlan(props) {
	const navigate = useNavigate();

	const [state, setState] = React.useState({
		title: "",
		lifetime_cost_switch: false,
		lifetime_cost: "",
		monthly_cost: "",
		annual_cost: "",
		total_year_cost: "",
		qrcode_limit: "",
		qrcode_scan_limit_switch: false,
		qrcode_scan_limit: "",
	});

	const [featuresState, setFeaturesState] = React.useState([
		{
			id: Math.random(),
			feature_name: "",
			support_text: "",
		},
	]);

	console.log(featuresState);

	const handleSubmit = () => {
		let newState = state;
		if (state.qrcode_scan_limit_switch) {
			delete newState.qrcode_scan_limit_switch;
		} else {
			newState.qrcode_scan_limit = "max";
			delete newState.qrcode_scan_limit_switch;
		}
		if (state.lifetime_cost_switch) {
			delete newState.lifetime_cost_switch;
		} else {
			newState.lifetime_cost = false;
			delete newState.lifetime_cost_switch;
		}
		newState.total_year_cost = state.annual_cost * 12;
		const newFeaturesState =
			featuresState.length > 0
				? featuresState
						?.map((ele) => {
							if (
								ele.feature_name === "" ||
								ele.feature_name === null ||
								ele.feature_name === undefined
							)
								return null;
							else return ele;
						})
						.filter((item) => item !== null)
				: [];
		const payload = {
			payload: {
				...newState,
				features: newFeaturesState,
			},
		};
		console.log(payload);

		createNewPlanApi(payload)
			.then((res) => {
				console.log(res);
			})
			.catch((err) => console.error(err));
	};

	return (
		<Page title="HotQR | Plan | Create">
			<PageHeader
				title="Create Subscription Plan"
				subTitle="Create new subscription plan."
				goBack={true}
			/>
			<FieldsComp state={state} setState={setState} />
			<Features
				type="basic"
				featuresState={featuresState}
				setFeaturesState={setFeaturesState}
			/>
			<Box sx={{px: 2, mt: 3, textAlign: "right"}}>
				<Button
					onClick={handleSubmit}
					variant="contained"
					color="primary"
					sx={{width: "150px"}}
				>
					Create Plan
				</Button>
			</Box>
		</Page>
	);
}

export default CreatePlan;

import React from "react";
import Lottie from "lottie-react";
import error from "../../Assets/Animation/error.json";

function Empty(props) {
	return (
		<div
			style={{
				display: "flex",
				justifyContent: "center",
				alignItems: "center",
				height: "80vh",
				width: "100%",
			}}
		>
			<Lottie animationData={error} style={{height: 300}} />
		</div>
	);
}

export default Empty;

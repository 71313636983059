import PropTypes from "prop-types";
import {Helmet} from "react-helmet-async";
import {forwardRef} from "react";
// @mui
import {Container} from "@mui/material";

// ----------------------------------------------------------------------

const Page = forwardRef(({children, title = "", meta, ...other}, ref) => (
	<>
		<Helmet>
			<title>{title}</title>
			{meta}
		</Helmet>
		<Container maxWidth="xl" ref={ref} {...other}>
			{children}
		</Container>
	</>
));

Page.propTypes = {
	children: PropTypes.node.isRequired,
	title: PropTypes.string,
	meta: PropTypes.node,
};

export default Page;

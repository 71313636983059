import {MdDashboard} from "react-icons/md";
import {AiFillSetting} from "react-icons/ai";
import {IoMdAnalytics} from "react-icons/io";
import {BsPeopleFill} from "react-icons/bs";
import {ImQrcode} from "react-icons/im";
import {FiLogOut} from "react-icons/fi";

export const topItems = [
	{
		id: 1,
		title: "Dashboard",
		icon: MdDashboard,
		link: "/",
		tag: "",
		active: "dashboard",
	},
	{
		id: 2,
		title: "Contents",
		icon: ImQrcode,
		link: "/contents",
		tag: "contents",
		active: "Contents",
	},
	{
		id: 3,
		title: "Categories",
		icon: IoMdAnalytics,
		link: "/categories",
		tag: "categories",
		active: "Categories",
	},
	{
		id: 4,
		title: "Users",
		icon: BsPeopleFill,
		link: "/users",
		tag: "users",
		active: "Users",
	},
	{
		id: 5,
		title: "Plans",
		icon: AiFillSetting,
		link: "/plans",
		tag: "plans",
		active: "Plans",
	},
];

export const bottomItems = [
	{
		id: 13,
		title: "Logout",
		icon: FiLogOut,
	},
];
